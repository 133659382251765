import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOut } from "../../Redux/Actions/SigninActions";

const SignOut = () => {
  let history = useHistory();

  const dispatch = useDispatch();
  const { signOutStatus } = useSelector((state) => state.login);
  useEffect(() => {
    dispatch(signOut());
  }, []);

  useEffect(() => {
    if (signOutStatus === "success") {
      const clearToken = localStorage.clear();
      const accessToken = localStorage.getItem("accessToken");
      history.push("/auth/signin");
    }
  }, [signOutStatus]);

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bolder",
        color: "blue",
        fontSize: "20",
        marginTop: "10%",
      }}
    >
      {" "}
      Logging out...
    </div>
  );
};

export default SignOut;
