import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { getCustomersList } from "../../../Redux/Actions/UserManagement"
import { Input, Table, Space, Card } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import "../../../Css/InputStyle.css";
import { useHistory,useParams } from "react-router-dom";

const Customer = () => {
  const dispatch = useDispatch();
  const { customerList } = useSelector(state => state.customerLists);
  const { Search } = Input;
  let history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    }
    dispatch(getCustomersList());
  }, [])

  const customer = () => {
    const items = [];
    customerList && customerList.map((item, key) => {
      key++;
      return items.push({
        key: key,
        sl_no: key,
        user_id: item.user_id,
        name: item.name,
        mobile: item.mobile,
        createdAt: item.createdAt,
        totalStores: item.totalStores,
      });

    })
    return items;
  }

  const onSearch = (e) => {
    let searchText = isNaN(e.target.value) === true ? `?name=${e.target.value}` : `?phone=${e.target.value}`;
    dispatch(getCustomersList(searchText))
  }
  const columns = [
    {
      title: "#",
      dataIndex: "sl_no",
      key: "sl_no",
      align: "center",
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Business',
      dataIndex: 'totalStores',
      key: 'business',
      align: 'center',
    },
    {
      title: "View",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Link className="stores_link" to={{ pathname: "/admin/customerinfo/" + record.user_id, state: { name: record.name, createdAt: record.createdAt, mobile: record.mobile, email: record.email, totalStores: record.totalStores } }}><EyeOutlined /></Link>
        </Space>
      )
    },
  ];
  return (
    <div className="main-content">
      <Container fluid>
        <Card title="User Management /Customers List" style={{ marginLeft: '50px' }}
        extra={
          <div className="search_input">
            <Input className="search_input" placeholder="Search" onChange={onSearch} />
          </div>}>
          <div className="business_table">
            <Table columns={columns} dataSource={customer()}  />
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default Customer;
