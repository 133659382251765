import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getManageQRList, QRCodeStatus } from "../../Redux/Actions/BusinessManagement";
import { Table, Modal, Card, Switch, message  } from 'antd';
import { useHistory } from "react-router-dom";
const { confirm } = Modal;


const ManageQR = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const { ManageQRList, success } = useSelector(state => state.businessList);
  let history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    } 
    dispatch(getManageQRList());
  }, [])

  useEffect(() => {
    if (success) {
      message.success(success);
      dispatch({ type: 'RESET_DATA' })
      dispatch(getManageQRList());
    }
  }, [success])

  const handleSwitchChange = (value) => {
    confirm({
      title: 'Do you Want to proceed?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const record = { store_id: value.stores_id, qr_code_status: value.qr_code_status === "Enabled" ? 3  : 2 }
        dispatch(QRCodeStatus(record))
      },
      onCancel() {
      },
    });
  }

  useEffect(() => {
    const items = [];
    ManageQRList && ManageQRList.map((item, key) => {
      key++;
      items.push({
        sl_no: key,
        key: item.store_id,
        stores_id: item.store_id,
        qr_code: item.kyc_status === 2 ? item.qr_code  : null,
        ownerName: item.ownerName,
        ownerMobile: item.ownerMobile,
        name: item.name,
        qr_code_status: item.qr_code_status === 1 ? "Requested" : item.qr_code_status === 2 ? "Enabled" : "Disabled",
        switchStatus: item.qr_code_status
      });
    })
    setData(items)
  }, [ManageQRList]);

  const columns = [
    {
      title: '#',
      dataIndex: 'sl_no',
      key: 'sl_no',
    },
    {
      title: 'QR Code',
      dataIndex: 'qr_code',
      key: 'qr_code',
      align: 'center',
      render: (text, record) => {
        return (record.qr_code === null ? <p style={{color: 'red'}}>Not Verified</p> : (<img src={`${record.qr_code}`} style={{ width: '70px', height: '70px' }} alt="Qr code"/>) );
      }
    },
    {
      title: 'Name',
      dataIndex: 'ownerName',
      key: 'ownername',
    },
    {
      title: 'Business',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'ownerMobile',
      key: 'ownerMobile',
    },
    {
      title: 'QR Status',
      dataIndex: 'qr_code_status',
      key: 'qr_code_status',
    },
    {
      title: 'Change Status',
      dataIndex: 'active',
      key: 'active',
      align: "center",
      render: (e, record) => (< Switch checkedChildren="Enable" unCheckedChildren="Disable" onChange={() => handleSwitchChange(record)} defaultChecked checked={(record.switchStatus === 2) ? true : false} />)
    },
  ];
  return (
    <div className="main-content">
      <Container fluid>
        <div>
          <Card title="Enable/Disable QR Code" style={{ marginLeft: '50px' }} >
            <Table columns={columns} dataSource={data} />
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default ManageQR;
