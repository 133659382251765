import { instance } from "../EcapsApi";

// GET BUSINESS USER LIST

export const getBusinessUserListSuccess = (data) => {
    return {
        type: 'GET_BUSINESS_USERLIST_SUCCESS',
        businessUserList: data,
    }
}
export const getBusinessUserListError = (error) => {
    return {
        type: 'GET_BUSINESS_USERLIST_FAIL',
        error: error,
    }
}

export const getBusinessUsersList = (searchText, page) => {
    searchText = searchText != undefined ? searchText : ""
    page = page != undefined ? page : ""
    return (dispatch) => {
        if (searchText) {
            instance
                .get(`su/business_users/${searchText}`)
                .then((response) => {
                    dispatch(getBusinessUserListSuccess(
                        response.data.data
                    )
                    );
                })
                .catch((err) => {
                    dispatch(getBusinessUserListError(err))
                })
        }
        else {
            instance
                .get(`su/business_users/${page}`)
                .then((response) => {
                    dispatch(getBusinessUserListSuccess(
                        response.data.data
                    )
                    );
                })
                .catch((error) => {
                    dispatch(getBusinessUserListError(error))
                })
        }
    }
}
//................................................................

// GET CUSTOMER USER LIST

export const getCustomersListSuccess = (data) => {
    return {
        type: 'GET_CUSTOMERS_LIST_SUCCESS',
        customersList: data,
    }
}
export const getCustomersListError = (error) => {
    return {
        type: 'GET_CUSTOMERS_LIST_FAIL',
        error: error,
    }
}

export const getCustomersList = (searchText, page) => {
    searchText = searchText != undefined ? searchText : ""
    page = page != undefined ? page : ""
    return (dispatch) => {
        if (searchText) {
            instance
                .get(`su/customers/${searchText}`)
                .then((response) => {
                    dispatch(getCustomersListSuccess(
                        response.data.data
                    )
                    );
                })
                .catch((error) => {
                    dispatch(getCustomersListError(error))
                })
        }
        else {
            instance
                .get(`su/customers/${page}`)
                .then((response) => {
                    dispatch(getCustomersListSuccess(
                        response.data.data
                    )
                    );
                })
                .catch((error) => {
                    dispatch(getCustomersListError(error))
                })
        }
    }
}
//.......................................................................
//below code - get store details of each customers.

export const getStoreDetailsSuccess = (data) => {
    return {
        type: 'GET_STOREDETAILS_USERS_SUCCESS',
        status: data,
    }
}
export const getStoreDetailsError = (error) => {
    return {
        type: 'GET_STOREDETAILS_USERS_ERROR',
        error: error,
    }
}
export const getStoreDetails = (userId) => {
    return (dispatch) => {
        instance
            .get(`su/store_list/${userId}`)
            .then((response) => {
                dispatch(getStoreDetailsSuccess(
                    response
                )
                );
            })
            .catch((error) => {
                dispatch(getStoreDetailsError(error))
            })
    }
}

// ...........................................................
// Below code - Change the status of Store

export const postStatusSuccess = (data) => {
    return {
        type: 'POST_STATUS_SUCCESS',
        status: data,
    }
}
export const postStatusError = (error) => {
    return {
        type: 'POST_STATUS_FAIL',
        error: error,
    }
}

export const changeStoreStatus = (data) => {
    return (dispatch) => {
        instance
            .post("su/store_status_change/", data)
            .then((response) => {
                dispatch(postStatusSuccess(
                    response.data
                )
                );
            })
            .catch((error) => {
                dispatch(postStatusError(error))
            })
    }
}