import { updatedObject } from "../Utility";

const initialState = {
    error: "",
    loading: false,
    accessToken: "",
    loginStatus: "",
    userType: "",
    loginError: "",
    signOutStatus: "",
    message: "",
    emailRequest: "",
    passwordReset: ""
};

const signOutFail = (state, action) => {
    return (state, {...state, loading: false, loginError: action.error });
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return { ...state, accessToken: action.accessToken.accessToken, message: action.message }
        case "LOGIN_FAIL":
            return { ...state, error: action.error, message: action.message, loginStatus: action.status }
        case "SIGNOUT_SUCCESS":
            return { ...state, signOutStatus: action.status }
        case "SIGNOUT_FAIL":
            return signOutFail(state, action);
        case "POST_REQUEST_EMAIL_SUCCESS":
            return { ...state, emailRequest: action.emailRequest, message: action.emailRequest.message, status: action.emailRequest.status }
        case "POST_REQUEST_EMAIL_ERROR":
            return { ...state, error: action.error }
        case "POST_PASSWORD_REST_SUCCESS":
            return {...state ,passwordReset: action , message: action.passwordReset.message, status: action.passwordReset.status}
        default:
            return state;
    }
};

export default LoginReducer;