import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Sidebar from "../SharedFeatures/Sidebar/Sidebar";
import routes from "../routes";
import "./Admin.css";

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //   componentDidUpdate(e) {
  //     if (e.history.action === "PUSH") {
  //       document.documentElement.scrollTop = 0;
  //       document.scrollingElement.scrollTop = 0;
  //       this.refs.mainPanel.scrollTop = 0;
  //     }
  //     if (
  //       window.innerWidth < 993 &&
  //       e.history.action === "PUSH" &&
  //       document.documentElement.className.indexOf("nav-open") !== -1
  //     ) {
  //       document.documentElement.classList.toggle("nav-open");
  //     }
  //   }
  //   componentWillMount() {
  //     if (document.documentElement.className.indexOf("nav-open") !== -1) {
  //       document.documentElement.classList.toggle("nav-open");
  //     }
  //   }

  getRoutes = (routes) => {
      return routes.map((route, key) => {
        if (route.collapse) {
          return this.getRoutes(route.views);
        }
        if (route.layout === "/admin") {
          return (
            <Route
              path={route.layout + route.path}
              key={key}
              render={(routeProps) => (
                <route.component
                  {...routeProps}
                  // handleClick={this.handleNotificationClick}
                />
              )}
            />
          );
        } else {
          return null;
        }
      });
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar />
        <main className="main-panel">
          <Switch>{this.getRoutes(routes)}</Switch>
        </main>
      </div>
    );
  }
}

export default AdminLayout;
