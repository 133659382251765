import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessList } from "../../Redux/Actions/BusinessManagement";
import { Container } from "react-bootstrap";
import { Input, Table, message, Space, Card } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import "../../Css/InputStyle.css"

const BusinessList = () => {
  const dispatch = useDispatch();
  const { businessList, status } = useSelector(state => state.businessList);
  const { Search } = Input;
  const [data, setData] = useState([])
  let history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    }
    if (status) {
      message.success(status)
      dispatch({ type: 'RESET_DATA' })
      dispatch(getBusinessList());
    }
  }, [status])

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    }
    dispatch(getBusinessList());
  }, [])

  useEffect(() => {
    const items = [];
    businessList && businessList.map((item, key) => {
      key++;
      return items.push({
        sl_no: key,
        key: item.stores_id,
        name: item.name,
        ownerName: item.ownerName,
        ownerMobile: item.ownerMobile,
        status: item.status === true ? "Active" : "Inactive",
        active: item.status,
        store_id: item.stores_id,
      });
    })
    setData(items)
  }, [businessList]);


  const onSearch = (e) => {
    let searchText = isNaN(e.target.value) === true ? `?name=${e.target.value}` : `?phone=${e.target.value}`;
    dispatch(getBusinessList(searchText));
  }


  const columns = [
    {
      title: '#',
      dataIndex: 'sl_no',
      key: 'sl_no',
    },
    {
      title: 'Business',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Owner',
      dataIndex: 'ownerName',
      key: 'ownerName',
    },
    {
      title: 'Mobile',
      dataIndex: 'ownerMobile',
      key: 'ownerMobile',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: "View",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Link className="stores_link" to={{ pathname: "/admin/storedetails/" + record.store_id}}><EyeOutlined /></Link>
        </Space>
      ),
    },
  ];

  return (
    <div className="main-content">
      <Container fluid>
        <Card title="Business Management /Business List" style={{ marginLeft: '50px' }} 
        extra={
          <div className="search_input">
            <Input className="search_input" placeholder="Search" onChange={onSearch} />
          </div> }>
          <div className="business_table">
            <Table columns={columns} dataSource={data} />
          </div>
        </Card>
      </Container>
    </div >
  );
};

export default BusinessList;
