const initialState = {
    error: "",
    loading: false,
    success: " ",
};

const DistributeDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "DISTRIBUTE_DATA_SUCCESS":
            return { ...state, success: action.formData.message }
        case "DISTRIBUTE_DATA_FAIL":
            return { ...state, error: action.error, }
        case "RETAIL_DATA_SUCCESS":
            return { ...state, success: action.formData.message }
        case "RETAIL_DATA_FAIL":
            return { ...state, error: action.error, }
        case 'RESET_DATA':
            return { ...state, success: '', error: '' }
        default:
            return state;
    }
}

export default DistributeDataReducer;