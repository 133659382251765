import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Switch, Avatar, message, Card, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { getStoreDetails, changeStoreStatus } from "../../../Redux/Actions/UserManagement"
import { ArrowLeftOutlined } from '@ant-design/icons';
import {  IconButton } from "@material-ui/core";
const { confirm } = Modal;

const Customerinfo = (props) => {
    const dispatch = useDispatch();
    const { createdAt, email, mobile, name, totalStores } = props.location.state
    const { customer_user_id } = useParams();
    const { usersStoreList, success } = useSelector(state => state.businessUser);

    useEffect(() => {
        dispatch(getStoreDetails(customer_user_id));
    }, [])

    useEffect(() => {
        if (success) {
            message.success(success);
            dispatch({ type: 'RESET_DATA' })
            dispatch(getStoreDetails(customer_user_id));
        }
    }, [success])

    const backClick = () => {
        window.history.back();
    }

    const storeList = () => {
        const items = [];
        usersStoreList && usersStoreList.map((item, key) => {
            key++;
            return items.push({
                sl_no: key,
                name: item.name,
                store_id: item.stores_id,
                mobile: item.ownerMobile,
                email: item.ownerEmail,
                owner: item.ownerName,
                receivable_amount: item.receivable_amount,
                payable_amount: item.payable_amount,
            })
        })
        return items;
    }


    const columns = [
        {
            title: '#',
            dataIndex: 'sl_no',
            key: 'sl_no',
        },
        {
            title: 'Store Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
        },
        {
            title: 'Amount to pay',
            dataIndex: 'payable_amount',
            key: 'payable_amount',
        },
        
        {
            title: 'Amount to recieve',
            dataIndex: 'receivable_amount',
            key: 'receivable_amount',
        },
    ];

    return (
        <div className="main-content">
            <Container fluid>
            <Card extra={<IconButton>
                    <ArrowLeftOutlined onClick={backClick}/>
                </IconButton>}
                title="User Details" style={{ marginLeft: '50px' }}>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Avatar shape="square" size={100} icon={<UserOutlined />} />
                        </Col>
                        <Col span={6}>
                            <p>Name : {name}</p>
                            <p>Email : </p>
                            <p>Phone Number : {mobile}</p>
                        </Col>
                        <Col span={6}>
                            {/* <p>Registration Date : {createdAt}</p> */}
                            <p>No of stores : {totalStores}</p>
                            <p>Phone Number : {mobile}</p>
                        </Col>
                    </Row>
                </Card>
                <Card title="Store Details" style={{ marginLeft: '50px' }}>
                    <div className="business_table">
                        <Table  columns={columns} dataSource={storeList()} style={{ marginLeft: '50px' }} />
                    </div>
                </Card>
            </Container>
        </div >
    )
}

export default Customerinfo
