import { updatedObject } from "../Utility";

const initialState = {
    error: "",
    loading: false,
    EnrolementRequestsList: "",
    EnrolementPartyDetails: "",
    EnrolementValidateStatus: "",
    sortedEnrolementRequests: ""
};

const getEnrolementRequestsListSuccess = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: null,
        EnrolementRequestsList: action.EnrolementRequestsList,
    });
};

const getEnrolementRequestsListFail = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: action.error,
    });
};

const getEnrolementPartyDetailsSuccess = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: null,
        EnrolementPartyDetails: action.EnrolementPartyDetails,
    });
};

const getEnrolementPartyDetailsFail = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: action.error,
    });
};

const validateEnrolementRequestSuccess = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: null,
        EnrolementValidateStatus: action.validateStatus,
    });
};

const validateEnrolementRequestFail = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: action.error,
    });
};

const clearEnrolementValidateStatus = (state, action) => {
    return updatedObject(state, {
        loading: false,
        EnrolementValidateStatus: "",
    });
};

const sortEnrolementRequestSuccess = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: null,
        sortedEnrolementRequests: action.sortedEnrolementRequests,
    });
};

const sortEnrolementRequestFail = (state, action) => {
    return updatedObject(state, {
        loading: false,
        error: action.error,
    });
};

const EnrolementRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ENROLEMENT_REQUESTS_LIST_SUCCESS":
            return getEnrolementRequestsListSuccess(state, action);
        case "GET_ENROLEMENT_REQUESTS_LIST_FAIL":
            return getEnrolementRequestsListFail(state, action);
        case "GET_ENROLEMENT_PARTY_DETAILS_SUCCESS":
            return getEnrolementPartyDetailsSuccess(state, action);
        case "GET_ENROLEMENT_PARTY_DETAILS_FAIL":
            return getEnrolementPartyDetailsFail(state, action);
        case "VALIDATE_ENROLEMENT_REQUEST_SUCCESS":
            return validateEnrolementRequestSuccess(state, action);
        case "VALIDATE_ENROLEMENT_REQUEST_FAIL":
            return validateEnrolementRequestFail(state, action);
        case "CLEAR_ENROLEMENT_VALIDATE_STATUS":
            return clearEnrolementValidateStatus(state, action);
        case "SORT_ENROLEMENT_REQUEST_SUCCESS":
            return sortEnrolementRequestSuccess(state, action);
        case "SORT_ENROLEMENT_REQUEST_FAIL":
            return sortEnrolementRequestFail(state, action);
        default:
            return state;
    }
};

export default EnrolementRequestReducer;