import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { postpasswordReset } from "../../../Redux/Actions/SigninActions";
import "./Signin.css";
import CustomButton from "../../../Components/Button/Button";
import { useHistory } from "react-router-dom";

const Reset = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [state, setState] = useState("");
  const [errors, setErrors] = useState({ name: '' });
  const [pageURL, setPageURL] = useState(0);
  const [token, setToken] = useState(0);
  const {message, status} = useSelector(state => state.login)

  useEffect(() => {
    if(status === 'error' && message){
      alert(message);
      setState({password: "", confirm_password:""})
    }else if(status === 'success' && message){
      alert(message);
      history.push("/auth/signin");
    }
  }, [status, message])
  
  useEffect(() => {
    setPageURL(window.location.href);
  })

  const handleChange = (e) => {
    let myregexp = /.*\/(.*?)$/;
    let match = myregexp.exec(pageURL);
    setToken(match[1]);
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const validate = () => {
    let input = state;
    let formIsValid = true;
    let errors = {};
    if (!input["password"]) {
      formIsValid = false;
      errors["password"] = "Password cannot be empty"
    }
    if (!input["confirm_password"]) {
      formIsValid = false;
      errors["confirm_password"] = "Confirm Password cannot be empty";
    }

    if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
      if (input["password"] !== input["confirm_password"]) {
        formIsValid = false;
        errors["confirm_password"] = "Password doesn't match"
      }
    }
    setErrors({ errors });
    return formIsValid;
  };

  const handleSubmit = () => {
    if (validate()) {
      dispatch(postpasswordReset(token, state))
    }
  }

  return (
    <Form name="basic" onFinish={handleSubmit} layout="vertical" validateMessages={validate} id="create-course-form">
      <div className="main">
        <div className="sign">Reset Password</div>
        <Form.Item label="Password" name="password" >
          <Input
            style={{ borderRadius: "8px" }}
            name="password"
            type="password"
            className="un"
            value={state.password}
            placeholder="Password"
            onChange={handleChange}
          />
          <div className="errorMsg">{errors && errors.errors && errors.errors.password}</div>
        </Form.Item>

        <Form.Item label="Confirm Password" name="confirm_password">
          <Input
            style={{ borderRadius: "8px" }}
            name="confirm_password"
            type="password"
            className="un"
            value={state.confirm_password}
            placeholder="Confirm Password"
            onChange={handleChange}
          />
          <div className="errorMsg">{errors && errors.errors && errors.errors.confirm_password}</div>
        </Form.Item>

        <div className="submit">
          <CustomButton label="Submit" onClick="" />
        </div>
      </div>
    </Form>
  )
}

export default Reset;