
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../routes";
import "./Auth.css"; 

class AuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return this.getRoutes(route.views);
      }
      if (route.layout === "/auth") {
        return (
          <Route
            path={route.layout + route.path}
            key={key}
            render={(routeProps) => (
              <route.component
                {...routeProps}
                // handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <div className="wrapper">
        <main>
          <Switch>{this.getRoutes(routes)}</Switch>
        </main>
      </div>
    );
  }
}

export default AuthLayout;
