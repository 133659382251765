import React, { useState } from "react";
import "antd/dist/antd.css";
import routes from "../../routes";
import { useLocation,useHistory } from "react-router-dom";
import { Menu } from "antd";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";

import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";

const { SubMenu } = Menu;

const Sidebar = () => {
  let location = useLocation();
  let history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const createLinks = (routes) => {
    return (<Menu theme="dark" mode="inline" style={{ height: "100%", background: '#013b8e'}}>
      {routes.map((route, key) => route.collapse ?
                (<SubMenu key={route.name}  title={route.name}>
                {route.views.map((subItem) => (
                    <Menu.Item key={subItem.mini} >
                      <NavLink to={subItem.layout + subItem.path}>
                      {subItem.name}
                      </NavLink>
                    </Menu.Item>)
                )}
                </SubMenu>) : null)}
      </Menu>)
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  return (
    // .center-col {
    //   flex: 1;
    //   background: #aaa;
    //   overflow-y: scroll;
    // }
      <div id="header">
        <ProSidebar>
          <SidebarHeader>
            <div className="logotext">
              <p onClick={()=>{ history.push("/auth/signin");}}>E-Caps</p>
            </div>
          </SidebarHeader>
          <SidebarContent className="center-col">{createLinks(routes)}</SidebarContent>
        </ProSidebar>
      </div>
  );
};

export default Sidebar;

