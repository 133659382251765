const initialState = {
    error: "",
    BuissnessList: [],
    CustomerList: []
};

const BuissnessReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BUISSNESSLIST_SUCCESS":
            return { ...state, BuissnessList: action.Buissnesslisting.list }
        case "GET_BUISSNESSLIST_ERROR":
            return { ...state, error: action.error, }
        case "GET_CUSTOMERLIST_SUCCESS":
            return { ...state, CustomerList: action.Customerlisting.list }
        case "GET_CUSTOMERLIST_ERROR":
            return { ...state, error: action.error, }
        case "GET_All_GENERATEREPORT_SUCCESS":
            return { ...state, AllReportList: action.AllReportListing }
        case "GET_All_GENERATEREPORT_ERROR":
            return { ...state, error: action.error, }
        default:
            return state;
    }
}

export default BuissnessReportReducer;

