import React, { useEffect } from "react";
import { Descriptions, Table, Switch, Row, Col, Avatar, message, Card, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStoreDetails, changeStoreStatus } from "../../../Redux/Actions/UserManagement"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import red from "@material-ui/core/colors/red";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { IconButton } from "@material-ui/core";
const { confirm } = Modal;

const Userinfo = (props) => {
    const dispatch = useDispatch();
    const { createdAt, mobile, name, total_stores } = props.location.state
    const { business_user_id } = useParams();
    const { usersStoreList, success } = useSelector(state => state.businessUser);
    useEffect(() => {
        dispatch(getStoreDetails(business_user_id));
    }, [])

    useEffect(() => {
        if (success) {
            message.success(success);
            dispatch({ type: 'RESET_DATA' })
            dispatch(getStoreDetails(business_user_id));
        }
    }, [success])

    const handleSwitchChange = (value) => {
        confirm({
            title: 'Do you Want to proceed?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                const data = { store_id: value.store_id, status: value.switchKey === true ? 'false' : 'true' }
                dispatch(changeStoreStatus(data))
            },
            onCancel() {
            },
        });

    }

    const backClick = () => {
        window.history.back();
    }

    const storeList = [];
    usersStoreList && usersStoreList.map((item, key) => {
        key++;
        storeList.push({
            sl_no: key,
            key: item.stores_id,
            store_id: item.stores_id,
            name: item.name,
            mobile: item.ownerMobile,
            email: item.ownerEmail,
            status: item.status === true ? "Active" : "Inactive",
            switchKey: item.status
        })
    })

    const columns = [
        {
            title: '#',
            dataIndex: 'sl_no',
            key: 'sl_no',
            align: "center",
        },
        {
            title: 'Store Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Change Status',
            dataIndex: 'active',
            key: 'action',
            align: "center",
            render: (e, record) => (<Switch onChange={() => handleSwitchChange(record)} defaultChecked checked={record.switchKey} />)
        }
    ];
    return (
        <div className="site-page-header-ghost-wrapper">
            <Container fluid>
                <Card extra={<IconButton>
                    <ArrowLeftOutlined onClick={backClick} />
                </IconButton>}
                    title="User Details" style={{ marginLeft: '50px' }}>
                    <Row gutter={16}>

                        <Col span={8}>
                            <Card>
                                <Avatar shape="square" size={100} icon={<UserOutlined />} />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <p><h5>{name}</h5></p>
                                <p>Email : </p>
                                <p><LocalPhoneIcon style={{ color: "red" }}></LocalPhoneIcon>{mobile}</p>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <p>Registration Date : {createdAt}</p>
                                <p>No of stores : {total_stores}</p>
                                <p>Phone Number : {mobile}</p>
                            </Card>
                        </Col>
                    </Row>
                </Card>
                {/* <PageHeader
                        className="site-page-header"
                        onBack={() => window.history.back()}
                        title="User Details"
                        style={{ marginLeft: '30px',position:'alternate' }}
                    /> */}
                <Card title="Store Details" style={{ marginLeft: '50px' }}>
                    <div className="business_table">
                        <Table columns={columns} dataSource={storeList} style={{ marginLeft: '50px' }} />
                    </div>
                </Card>
            </Container>
        </div >
    );
};

export default Userinfo;

