import { Table, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core'
import {makeStyles} from "@material-ui/styles"
import Pagination from "@mui/material/Pagination";
import React, { useState } from 'react'
// import {PAGE_SIZE} from ''

// const useStyles = makeStyles(theme => ({
//     table: {
//         marginTop: theme.spacing(5),
//         '&': {
//             borderRadius: '20px',
//         },
//         '& thead th': {
//             fontWeight: '600',
//             fontSize:'15px',
//             letter: '8%',
//             color: "#f8f7fc",
//             lineHeight: '22.5px',
//             backgroundColor: "#1A0045",
//         },
//         '& tbody td': {
//             fontWeight: '500',
//         },
//         '& tbody tr:hover': {
//             backgroundColor: '#fffbf2',
//         },
//     },
// }))

export default function useTable(records, headCells, totalCount) {
    // const classes = useStyles();
    const [pageNo, setPageNo] = useState(1);
    const [slNo, setSlNo] = useState(0);

    const handleChange = (event, value) => {
        setPageNo(value);
        setSlNo(value - 1);
      };

    // const TblContainer = props => (
    //     //  <Table className={classes.table}>
    //     //     {props.children}
    //     // </Table>
    // )



    const TblHead = props => (
        <TableHead>
            <TableRow>
                {
                    headCells.map(headCell => (
                        <TableCell key={headCell.label}>
                            {headCell.label}
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    )

    const TblPagination = () => (
        <Pagination
        //   count={Math.ceil(totalCount / PAGE_SIZE)}
          page={pageNo}
          onChange={handleChange}
        />
      );

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // }

    // const handleChangeRowsPerPage = event => {
    //     setRowsPerPage(parseInt(event.target.value, 5))
    //     setPage(0);
    // }

    // const TblPagination = () => (<TablePagination
    //     component="div"
    //     page={page}
    //     rowsPerPageOptions={pages}
    //     rowsPerPage={rowsPerPage}
    //     count={records.length}
    //     onChangePage={handleChangePage}
    //     onChangeRowsPerPage={handleChangeRowsPerPage}
    // />)
    return {
        // TblContainer,
        TblHead,
        TblPagination,
        pageNo,
        slNo 
    };
}
