import React, { useState, useEffect } from "react";
import KycTab from "./KycTab";
import { Card, Descriptions, Divider, Modal, Switch, message } from 'antd';
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { approveKycStatus, getKycDetails } from "../../Redux/Actions/BusinessManagement";
import "../../Css/InputStyle.css";
const { confirm } = Modal;


const KycDetails = (props) => {
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState(0);
  const { success, kycDetails } = useSelector(state => state.businessList);
  const { name, ownerMobile } = props.location.state

  useEffect(() => {
    let pageUrl = window.location.href;
    let myregexp = /.*\/(.*?)$/;
    let match = myregexp.exec(pageUrl);
    setStoreId(match[1]);
    dispatch(getKycDetails(match[1]))
  }, [])

  useEffect(() => {
    if (success) {
      message.success(success);
      dispatch({ type: 'RESET_DATA' })
    }
  }, [success])

  const onChange = (value) => {
    confirm({
      title: 'Do you Want to proceed?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        let status = value === true ? 2 : 3;
        const data = { store_id: storeId, kyc_status: value === true ? 2 : 3, kyc_rejection_reason: status === 2 ? "Approved" : "Documents pending" }
        dispatch(approveKycStatus(data))
      },
      onCancel() {
      },
    });

  }

  return (
    <>
      {/* <div className="site-page-header-ghost-wrapper"> */}
      <Container fluid>
        <Card title="KYC Documents" style={{ marginLeft: '50px' }}>
          {/* <PageHeader
            className="site-page-header"
            onBack={() => window.history.back()}
            title="KYC Documents"
            style={{ marginLeft: '30px' }}
          /> */}
          <Descriptions style={{ marginLeft: '90px' }}>
            <Descriptions.Item label="Store Name">{name}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{ownerMobile}</Descriptions.Item>
            <Descriptions.Item label="Approve/Reject KYC Status">
              <Switch checkedChildren="Approve" unCheckedChildren="Reject" onChange={onChange} defaultChecked checked={(kycDetails.kyc_status === 2) ? true : false} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Container>
      <Divider />
      <KycTab kycDetails={kycDetails} />
      {/* </div> */}

    </>
  );
};

export default KycDetails;
