import { combineReducers } from "redux";
import SigninReducer from "./SigninReducer";
import EnrolementReducer from "./EnrolementReducer"
import UserManagementReducer from "./UserManagementReducer";
import PaymentManagementReducer from "./PaymentManagementReducer";
import BusinessManagementReducer from "./BusinessManagementReducer";
import FAQReducer from "./FAQReducer";
import BuissnessReportReducer from "./ReportReducer";
// import CustomerListReducer from "./CustomerListReducer";
// import GenerateReportReducer from "./GenerateReportReducer";
import GenerateAllReportReducer from "./GenerateAlReportReducer";
import DistributeDataReducer from "./EcapsReducer";
import RetailDataReducer from "./EcapsReducer"
const RootReducer = combineReducers({
  login: SigninReducer,
  enrolement: EnrolementReducer,
  businessUser: UserManagementReducer,
  customerLists: UserManagementReducer,
  businessList: BusinessManagementReducer,
  FAQ: FAQReducer,
  PaymentManagementList: PaymentManagementReducer,
  Report:BuissnessReportReducer,
  // GenerateReport:GenerateReportReducer,
  GenerateAllReport:GenerateAllReportReducer,
  Distribute: DistributeDataReducer,
});

export default RootReducer;
