import React, { useState, useEffect } from "react";
import { Paper, Card, Typography, Grid } from "@material-ui/core";
import SharedClasses from "../../SharedFeatures/SharedClasses";
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select'
import Button from "@mui/material/Button";
import ImportModal from "../../common/ImportModal";
import { postDistributeData } from "../../Redux/Actions/EcapsManagement";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Distributers = () => {
  const classes = SharedClasses();
  const [open, setOpen] = useState(false);
  const [showPageNtn, setShowPageNtn] = useState(false);
  const [fileType, setFileType] = useState(true);
  const [messege,setMessege]  = useState(null)
  const { success } = useSelector((state) => state.Distribute);

  useEffect(() => {
    if (success) {
      // message.success(success);
      dispatch({ type: "RESET_DATA" });
    }
  }, [success]);

  const onBtnClick = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();

  const readExcel = (e) => {
    const data = e.target.files[0];
    if (
      data.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileType(false);
    } else {
      setFileType(true);
      const formData = new FormData();
      formData.append("excel", data, data.name);
      setTimeout(() => {
        dispatch(postDistributeData(formData));
        message.success(`${success}File has been uploaded sucessfully`);
      }, 5000);

      setOpen(!open);
    }
  };
  return (
    <>
      <Paper className={classes.commonPaper}>
        <Typography variant="h4" className={classes.typographyHead}>
          Import Distributers
        </Typography>
        <Card className={classes.distributerCard}>
          <Grid className={classes.items} container spacing={3}>
            <Grid item xs={3}>
              <Typography className={classes.typographyContent} variant="h5">
                Select Excel File
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid>
              <Button
                onClick={onBtnClick}
                className={classes.uploadbutton}
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </Grid>
          </Grid>
        </Card>
        {open ? (
          <ImportModal
            open={open}
            onClose={onBtnClick}
            // title={CUST_MODAL_HEADER}
            readExcel={readExcel}
            fileType={fileType}
            // message={message}
            // requestId={requestId}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default Distributers;
