import React from "react"
import { Card, Tabs, Row, Col } from 'antd';
const { TabPane } = Tabs;

const KycTab = (props) => {
    return (
        <>
            <Card style={{ marginLeft: '105px' }}>
                <Tabs defaultActiveKey="1" className="kyc_tab" >
                    <TabPane
                        tab={
                            <span>
                                Aadhar card
                            </span>
                        }
                        key="1"
                    ><Row gutter={16}>
                            <Col span={8}>
                                <Card title="Aadhar Front Page" bordered={false}>
                                    <Card
                                        style={{ width: 300, marginLeft: '0px', marginTop: '80px' }}
                                        cover={
                                            props.kycDetails.aadhar_front_image === null ? 
                                            (<p style={{color:'red', fontWeight: 'bold'}}>No Image</p>) :
                                            (<img
                                                alt="example"
                                                src={`${props.kycDetails.path}${props.kycDetails.aadhar_front_image}`}
                                            />)
                                        }
                                    >
                                    </Card>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Aadhar Back Page" bordered={false}>
                                    <Card
                                        style={{ width: 300, marginLeft: '0px', marginTop: '80px' }}
                                        cover={
                                            props.kycDetails.aadhar_back_side_image === null ? 
                                            (<p style={{color:'red', fontWeight: 'bold'}}>No Image</p>) :
                                            (<img
                                                src={`${props.kycDetails.path}${props.kycDetails.aadhar_back_side_image}` }
                                            />)
                                        }
                                    >
                                    </Card>
                                </Card>
                            </Col>

                        </Row>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                Pan Card
                            </span>
                        }
                        key="2"
                    ><Row gutter={16}>
                            <Col span={8}>
                                <Card title="Pan Card" bordered={false}>
                                    <Card
                                        style={{ width: 300, marginLeft: '0px', marginTop: '80px' }}
                                        cover={
                                            props.kycDetails.pan_card_image === null ? 
                                            (<p style={{color:'red', fontWeight: 'bold'}}>No Image</p>) :
                                            (<img
                                                alt="example"
                                                src={`${props.kycDetails.path}${props.kycDetails.pan_card_image}`}
                                            />)
                                        }
                                    >
                                    </Card>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                Voter's Id Card
                            </span>
                        }
                        key="3"
                    >
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Voter's ID Front Page" bordered={false}>
                                    <Card
                                        style={{ width: 300, marginLeft: '0px', marginTop: '80px' }}
                                        cover={
                                            props.kycDetails.voter_id_front_image === null ? 
                                            (<p style={{color:'red', fontWeight: 'bold'}}>No Image</p>) :
                                            (<img
                                                src={`${props.kycDetails.path}${props.kycDetails.voter_id_front_image}`}
                                            />) 
                                        }
                                    >
                                    </Card>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Voter's ID Back Page" bordered={false}>
                                    <Card
                                        style={{ width: 300, marginLeft: '0px', marginTop: '80px' }}
                                        cover={
                                            props.kycDetails.voter_id_back_side_image === null ? 
                                            (<p style={{color:'red', fontWeight: 'bold'}}>No Image</p>) :
                                            (<img
                                                src={`${props.kycDetails.path}${props.kycDetails.voter_id_back_side_image}`}
                                            />)
                                        }

                                    >
                                    </Card>
                                </Card>
                            </Col>

                        </Row>
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}

export default KycTab;