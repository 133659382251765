import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPayInList } from "../../Redux/Actions/PaymentManagement";
import { Container } from "react-bootstrap";
import { Form, Table, DatePicker, Card, Row, Col } from 'antd';
import { useHistory } from "react-router-dom";


const PayIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { PayInList } = useSelector(state => state.PaymentManagementList);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === 'undefined') {
      history.push("/auth/signin");
    }
    dispatch(getPayInList());
  }, [])

  const payIn = () => {
    const items = [];
    PayInList && PayInList.map((item, key) => {
      key++;
      return items.push({
        sl_no: key,
        key: item.pay_type,
        amount: item.amount,
        store_name: item.store_name,
        name: item.name,
        date: item.date,
        time: item.time,
        payment_mode: item.payment_mode,
        service_charge: item.service_charge,
        document: item.document,
      });

    })
    return items;
  }

  const columns = [
    {
      title: "#",
      dataIndex: "sl_no",
      key: "sl_no",
      align: "center",
    },
    {
      title: 'Business',
      dataIndex: 'store_name',
      key: 'store_name',
      align: "center",
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: "center",
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: "center",
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      align: "center",
    },
    {
      title: 'Mode',
      dataIndex: 'payment_mode',
      key: 'payment_mode',
      align: "center",
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: "center",
    },
    {
      title: 'Transaction Fee',
      dataIndex: 'service_charge',
      key: 'service_charge',
      align: "center",
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
    },
  ];
  const onOk = (value) => {
  }
  return (
    <div className="main-content">
      <Container fluid>
        <Card
          title="Pay-Ins - Money paid by Customer to Business" style={{ marginLeft: '50px' }} >
          {/* <Row>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item label="Select Business" style={{ width: '200px' }}>
                <SelectBox
                  isMulti={false}
                  isSearchable={true}
                  value=""
                  onChange=""
                  options=""
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item label="From" style={{ width: '200px', marginTop: '0px' }}>
                <DatePicker showTime onChange="" onOk={onOk} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
              <Form.Item label="To" style={{ width: '200px', marginTop: '0px' }}>
                <DatePicker showTime onChange="" onOk={onOk} />
              </Form.Item>
            </Col>
          </Row> */}
          <Table columns={columns} dataSource={payIn()} />
        </Card>
      </Container>
    </div>
  );
};

export default PayIn;
