import { instance, http } from "../EcapsApi";
import React, { useState } from "react";

export const getBuissnessListSuccess = (data) => {
  return {
    type: "GET_BUISSNESSLIST_SUCCESS",
    Buissnesslisting: data,
  };
};

export const getBuissnessListError = (error) => {
  return {
    type: "GET_BUISSNESSLIST_ERROR",
    error: error,
  };
};

export const getBuissnessList = (state) => {
  return (dispatch) => {
    instance
      .get("su/store_name_search")
      .then((response) => {
        dispatch(getBuissnessListSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getBuissnessListError(error));
      });
  };
};

//................................................................
// Below code - SELECT CUSTOMER

export const getCustomerListSuccess = (data) => {
  return {
    type: "GET_CUSTOMERLIST_SUCCESS",
    Customerlisting: data,
  };
};

export const getCustomerListError = (error) => {
  return {
    type: "GET_CUSTOMERLIST_ERROR",
    error: error,
  };
};

export const getCustomerList = (buisId) => {
  // const url = buisId ?  `/su/user_name_search?store_id=${buisId}` : `/su/user_name_search`
  return (dispatch) => {
    instance
      .get(`/su/user_name_search?store_id=${buisId}`)
      .then((response) => {
        console.log("cust response", response);
        dispatch(getCustomerListSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(getCustomerListError(error));
      });
  };
};

//................................................................
// Below code - GENERATE-All REPORTS

export const getAllGenerateReportSuccess = (data) => {
  console.log("data act", data);
  return {
    type: "GET_All_GENERATEREPORT_SUCCESS",
    AllReportListing: data,
  };
};

export const getAllGenerateReportError = (error) => {
  return {
    type: "GET_All_GENERATEREPORT_ERROR",
    error: error,
  };
};

export const getAllGenerateReport = (
  store,
  user,
  paytype,
  fromDate,
  toDate
) => {
  return (dispatch) => {
    let store_id = store === undefined ? "" : store;
    let user_id = user === undefined ? "" : user;
    let pay_type = paytype === undefined ? "" : paytype;
    let from_date = fromDate === undefined ? "" : fromDate;
    let to_date = toDate === undefined ? "" : toDate;
    const url =
      store_id === "" &&
      user_id === "" &&
      pay_type === "" &&
      from_date === "" &&
      to_date === ""
        ? "su/reports"
        : `su/reports?store_id=${store_id}&user_id=${user_id}&pay_type=${pay_type}&from_date=${from_date}&to_date=${to_date}`;
    console.log("url", url);
    http
      .get(url)
      .then((response) => {
        console.log(".then response", response);
        dispatch(getAllGenerateReportSuccess(response.data.transactions));
      })
      .catch((error) => {
        dispatch(getAllGenerateReportError(error));
      });
  };
};
