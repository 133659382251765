import React, { useEffect } from "react";
import DashboardContent from "./DashboardContent";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";

// import DashboardContent1 from "../../Containers/Main/DashboardContent1"

const Dashboard = () => {
  let history = useHistory();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    } 
  },[])

  return (
    <div className="main-content">
      <DashboardContent />
      {/* <DashboardContent1/> */}
    </div>
  );
};

export default Dashboard;
