import { instance } from "../EcapsApi";

// POST FAQ Category
export const postFaqCategorySuccess = (data) => {
    return {
        type: 'POST_FAQ_CATEGORY_SUCCESS',
        payload: data,
    }
}

export const postFaqCategoryError = (error) => {
    return {
        type: 'POST_FAQ_CATEGORY_ERROR',
        error: error,
    }
}

export const postFAQCategory = (state) => {
    return (dispatch) => {
        instance
            .post("su/create_faq_category/", state)
            .then((response) => {
                dispatch(postFaqCategorySuccess(
                    response.data
                )
                )
            })
            .catch((error) => {
                dispatch(postFaqCategoryError(error))
            })
    }
}
//................................................................
//get FAQ Category
export const getFaqCategorySuccess = (data) => {
    return {
        type: 'GET_FAQ_CATEGORY_SUCCESS',
        FAQcategory: data,
    }
}

export const getFaqCategoryError = (error) => {
    return {
        type: 'GET_FAQ_CATEGORY_ERROR',
        error: error,
    }
}

export const getFAQCategory = (state) => {
    return (dispatch) => {
        instance
            .get('su/faq_categories')
            .then((response) => {
                dispatch(getFaqCategorySuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getFaqCategoryError(error))
            })
    }
}
//..................................................................... 
//get QA based on category
export const getqaSuccess = (data) => {
    return {
        type: 'GET_QA_SUCCESS',
        faqList: data,
    }
}

export const getqaError = (error) => {
    return {
        type: 'GET_QA_ERROR',
        error: error,
    }
}

export const getQA = (id) => {
    return (dispatch) => {
        instance
            .get(`su/faq_list/${id}`)
            .then((response) => {
                dispatch(getqaSuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getqaError(error))
            })
    }
}
//................................................................
//below code->post FAQ
export const postFaqSuccess = (data) => {
    return {
        type: 'POST_FAQ_SUCCESS',
        payload: data,
    }
}

export const postFaqError = (error) => {
    return {
        type: 'POST_FAQ_ERROR',
        error: error,
    }
}

export const postFAQ = (state) => {
    return (dispatch) => {
        instance
            .post("su/create_faq/", state)
            .then((response) => {
                dispatch(postFaqSuccess({
                    data: response.data,
                })
                );
            })
            .catch((error) => {
                dispatch(postFaqError(error))
            })
    }
}

//................................................................
//Below code-> delete FAQ category
export const deleteFAQCategorySuccess = (data) => {
    return {
        type: 'DELETE_FAQ_CATEGORY_SUCCESS',
        id: data.id.faq_category_id,
        status: data.status
    }
}

export const deleteFAQCategoryError = (data) => {
    return {
        type: 'DELETE_FAQ_CATEGORY_ERROR',
        status: data,
    }
}

export const deleteCategory = (id) => {
    return (dispatch) => {
        instance
            .post("su/delete_faq_category/", id)
            .then((response) => {
                if(response.data.status == 'success') {
                    dispatch(deleteFAQCategorySuccess({
                        id: id,
                        status: response.data
                    }));
                } else {
                    dispatch(deleteFAQCategoryError(response.data))
                }
            })
            .catch((error) => {
                dispatch(deleteFAQCategoryError(error))
            })
    }
}
//..............................................................
//Below code->delete FAQ
export const deleteFAQSuccess = (data) => {
    return {
        type: 'DELETE_FAQ_SUCCESS',
        id: data.id.faq_id,
        status: data.status
    }
}

export const deleteFAQError = (data) => {
    return {
        type: 'DELETE_FAQ_ERROR',
        status: data,
    }
}

export const deleteQA = (id) => {
    return (dispatch) => {
        instance
            .post("su/delete_faq/", id)
            .then((response) => {
                    dispatch(deleteFAQSuccess({
                        id: id,
                        status: response.data
                    }));
            })
            .catch((error) => {
                dispatch(deleteFAQError(error))
            })
    }
}
//...............................................................
//below code => update FAQ

export const updateFaqSuccess = (data) => {
    return {
        type: 'UPDATE_FAQ_SUCCESS',
        payload: data,
    }
}

export const updateFaqError = (error) => {
    return {
        type: 'UPDATE_FAQ_ERROR',
        error: error,
    }
}

export const updateFAQ = (state) => {
    return (dispatch) => {
        instance
            .post("su/update_faq/", state)
            .then((response) => {
                dispatch(updateFaqSuccess({
                    data: response.data,
                })
                );
            })
            .catch((error) => {
                dispatch(updateFaqError(error))
            })
    }
}
// .................................................................
//below code => update faq category

export const updateFaqCategorySuccess = (data) => {
    return {
        type: 'UPDATE_FAQ_CATEGORY_SUCCESS',
        payload: data,
    }
}

export const updateFaqCategoryError = (error) => {
    return {
        type: 'UPDATE_FAQ_CATEGORY_ERROR',
        error: error,
    }
}

export const updateFaqCategory = (state) => {
    return (dispatch) => {
        instance
            .post("su/update_faq_category", state)
            .then((response) => {
                dispatch(updateFaqCategorySuccess({
                    data: response,
                })
                );
            })
            .catch((error) => {
                dispatch(updateFaqCategoryError(error))
            })
    }
}
