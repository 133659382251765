import { updatedObject } from "../Utility";

const initialState = {
  error: "",
  loading: false,
  businessUsers: "",
  customerList: "",
  customerStoreList: '',
  page: '',
  status: '',
  success: '',
  usersStoreList: '',
};
const UserManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BUSINESS_USERLIST_SUCCESS":
      return { ...state, businessUsers: action.businessUserList.list, page: action.businessUserList.page }
    case "GET_BUSINESS_USERLIST_FAIL":
     return {...state, error: action.error}
    case "GET_CUSTOMERS_LIST_SUCCESS":
      return { ...state, customerList: action.customersList.list }
    case "GET_CUSTOMERS_LIST_FAIL":
      return { ...state, error: action.error }
    case "GET_STOREDETAILS_USERS_SUCCESS":
      return { ...state, usersStoreList: action.status.data.data }
    case "GET_STOREDETAILS_USERS_ERROR":
      return { ...state, message: action.data.message }
      case "POST_STATUS_SUCCESS":
        return { ...state, success: action.status.message }
    case "POST_STATUS_FAIL":
        return { ...state, error: action }
    case 'RESET_DATA':
      return { ...state, status: '', success:'' }
    default:
      return state;
  }
};

export default UserManagementReducer;