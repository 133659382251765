import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./Layouts/Auth";
import AdminLayout from "./Layouts/Admin";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";


function App() {
  let history = useHistory();
  const dispatch = useDispatch()

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null && accessToken === ' undefined') {
      // dispatch(signOut());
      history.push("/admin/dashboard");
    } 
    // sessionlogout()
  }, []);


  return (
    <Switch>
      <Route path="/auth" render={props => <AuthLayout {...props} />} />
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Redirect from="/" to="/auth/signin"/>
    </Switch>
  );
}

export default App;
