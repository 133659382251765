import React, { useState } from "react";
import { Form, Input } from "antd";
import CustomButton from "../../../Components/Button/Button";
import "./Signin.css";

const SigninContent = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const submitHandler = (event) => {
    props.signin(username, password);
  };

  const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: "'${label}' is not a valid email!",
    },
  };
  return (
    <Form  onFinish={submitHandler} layout="vertical" validateMessages={validateMessages}>
        <Form.Item className="label" label="Username" name="email" rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
          >
        <Input
          style={{ borderRadius: "8px" }}
          className="un"
          value={username}
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Item>
        <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
        <Input
          style={{ borderRadius: "8px" }}
          className="pass"
          value={password}
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        </Form.Item>
      <div className="forgot">
        <a href="/auth/forgot">Forgot Password</a>
      </div>
      <div className="submit">
        <CustomButton label="Sign In" onClick={submitHandler} />
      </div>
    </Form>
  );
};

export default SigninContent;
