import React from "react";
import "./Button.css";

const Button = (props) => {
  return (
    <div className="btn">
      <button onClick={props.onClick}>{props.label}</button>
    </div>
  );
};

export default Button;
