import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const UseTable = (data) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <div style={{ marginLeft: "-700px", width: "1400px", marginTop: "100px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1000 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Store name</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Pay Type</StyledTableCell>
                <StyledTableCell align="center">Payment Mode</StyledTableCell>
                <StyledTableCell align="center">Service Charge</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data && data.data.map((item,index) => {
                return(
                <StyledTableRow key={index.toString()}>
                  <StyledTableCell component="th" scope="row" align="center">
                   {item.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.store_name}</StyledTableCell>
                  <StyledTableCell align="center">{item.date}</StyledTableCell>
                  <StyledTableCell align="center">
                    {item.pay_type === "CB" ? "Paid" : "Received" }
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.payment_mode}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                   
                  </StyledTableCell>
                  <StyledTableCell align="center">{item.status === 4 ? "Compleated" : "Progressing" }</StyledTableCell>
                  <StyledTableCell align="center">{item.time}</StyledTableCell>
                </StyledTableRow>)
             })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default UseTable;
