import React, { useState, useEffect } from "react";
import { Form, Input, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./Signin.css";
import { postRequestEmail } from "../../../Redux/Actions/SigninActions";
import CustomButton from "../../../Components/Button/Button";

const Forgot = () => {
    const [email, setEmail] = useState("");
    const [state, setState] = useState({});
    const { error, message, status} = useSelector(state => state.login);
    const dispatch = useDispatch();

    useEffect(() => {
        if(status === 'success' && message){
            alert(message);
        }
    }, [status, message])
    
    const validateMessages = {
    required: "'${label}' is required!",
    types: {
      email: "'${label}' is not a valid email!",
    },
  };


    const onChange = (e) => {
        // setState({ ...state, email: e.target.value, redirect_url: `${process.env.REACT_APP_API_BASE_URL}/reset_password` })
        setState({ ...state, email: e.target.value, redirect_url: `http://localhost:3001/reset_password/` })
    };

    const sendEmail = () => {
        dispatch(postRequestEmail(state))
    }


    return (
        <>
            {error ? (<Alert type="error" message="Invalid email" banner closable />) : null}
            <Form name="basic" onFinish={sendEmail} layout="vertical" validateMessages={validateMessages} >
                <div className="main">
                    <div className="sign">
                        <p>Forgot Password</p>
                    </div>
                    <Form.Item
                        label="Email Id"
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                            },
                        ]}
                    >
                        <Input
                            style={{ borderRadius: "8px" }}
                            type="email"
                            name="email"
                            className="un"
                            value={email}
                            placeholder="Email Id"
                            onChange={onChange}
                        />
                    </Form.Item>
                    <div className="submit">
                        <CustomButton label="Submit" />
                    </div>
                </div>
            </Form>
        </>
    )
}

export default Forgot;