import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./modalStyles.css";
import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import useStyles, { makemyStyles } from "../SharedFeatures/SharedClasses";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 450,
  bgcolor: "background.paper",
  border: "2px solid #1A0045",
  boxShadow: 24,
  p: 4,
};

const ImportModal = ({
  open,
  onClose,
  title,
  readExcel,
  fileType,
  message,
  requestId,
}) => {
  const [files, setFiles] = useState();

  const [fileUploaded, setFileUploaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (message && requestId && fileType) {
      setFileUploaded(true);
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);
    }
  }, [requestId]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="modalHeader"
          >
            {title}
          </Typography>
          <AiOutlineClose className="closeIcon" onClick={onClose} />
          <Typography id="modal-modal-description" className="modalSubHeader">
            .xls and .xlsx files are allowed
          </Typography>
          <div>
            <label for="file-upload" className="custom-file-upload">
              <div className="in-box">
                <BiCloudUpload className="uploadIcon" />
                <Typography
                  id="modal-modal-description"
                  className="modalSubHeader"
                >
                  <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => setFiles(e)}
                  />
                </Typography>
              </div>
            </label>
            {/* <input id="file-upload" type="file" onChange={(e) => setFiles(e)} /> */}
          </div>
          <button
            className={classes.newButton}
            type="submit"
            variant="outlined"
            // className={classes.newButton}
            style={{ position: "relative", left: "250px", top: "385px" }}
            onClick={() => readExcel(files)}
            hidden={fileUploaded ? true : false}
          >
            Upload Data
          </button>
          {fileUploaded ? (
            <div className="uploadedAlert">
              <h3>{message}</h3>
            </div>
          ) : (
            ""
          )}

          {!fileType ? (
            <div className="fileTypeError">
              <h3>
                File does not support. You must use .xls or .xlsx files only
              </h3>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ImportModal;
