import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getBuissnessList,
  getCustomerList,
  getAllGenerateReport,
} from "../../Redux/Actions/BuissnessReport";
import { useSelector, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import SelectBox from "react-select";
import {
  Select as MuiSelect,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import useTable from "../../SharedFeatures/useTable";
import moment from "moment";
import Paper from "@mui/material/Paper";
import UseTable from "./UseTable";
import Button from "@mui/material/Button";
import { useForm } from "../../SharedFeatures/UseForm";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { instance } from "../../Redux/EcapsApi";

const initialValues = {
  business: "",
  customer: "",
  payType: "",
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px",
    width: "130%",
    marginTop: "14px",
  },
  textField: {
    width: "100%",
    maxWidth: "500px",
    height: "45px",
  },
  card: {
    marginLeft: "80px",
    marginTop: "50px",
    marginRight: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row"
  },
  firstSection: {
    width: "40vw",
    padding: "60px",
  },
  secondSection: {
    width: "40vw",
    padding: "60px",
  },
  displayTable: {
    marginLeft: "10px",
  },
}));
const paytype = [
  { id: "BC", name: "BC" },
  { id: "CB", name: "CB" },
];
function ReportsOne() {
  const [value, setValue] = useState(initialValues);
  const [state, setState] = useState({});
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const classes = useStyles();
  const [data, setData] = useState(true);
  const [spinnerIsVisible, setSpinnerIsVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [isDataEnd, setIsDataEnd] = useState(false);
  const [showPageNtn, setShowPageNtn] = useState(false);

  const { TblPagination } = useTable();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues) {
      (temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.") ||
        (temp.email = fieldValues.email ? "" : "This field is required.");
    }
    if ("mobile" in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required.";
    if ("region" in fieldValues)
      temp.region = fieldValues.region ? "" : "This field is required.";
    if ("userid" in fieldValues)
      temp.userid = fieldValues.userid ? "" : "This field is required.";
    if ("customertype" in fieldValues)
      temp.customertype =
        fieldValues.customertype.length !== 0 ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue({
      ...value,
      [name]: value,
    });
  };
  const dispatch = useDispatch();

  const { pageNo, slNo } = useTable();

  const { BuissnessList, CustomerList, AllReportList } = useSelector(
    (state) => state.Report
  );

  const ids = BuissnessList.map((item) => {
    return item.stores_id;
  });

  var fromDateFormat = moment(fromDate).format("YYYY-M-D");
  var toDateFormat = moment(toDate).format("YYYY-M-D");

  const business = [];
  BuissnessList &&
    BuissnessList.map((item) => {
      return business.push({ value: item.stores_id, label: item.name });
    });

  const customer = [];
  CustomerList &&
    CustomerList.map((item) => {
      return customer.push({ value: item.user_id, label: item.name });
    });

  useEffect(() => {
    dispatch(getBuissnessList());
    dispatch(getAllGenerateReport());
    setToDate("");
    setSpinnerIsVisible(true);
  }, [dispatch]);

  const handleBusinessChange = (value) => {
    let buisId = value.value;
    setState({ ...state, buisness_data: value });
    console.log("value", value);
    console.log("buissnessId", buisId);
    dispatch(getCustomerList(buisId));
  };

  const handleUserChange = (value) => {
    setState({ ...state, user_data: value });
    const cusId = state.user_id && state.user_data;
    console.log("cusid", cusId);
  };
  const handleClick = (event) => {
    event.preventDefault();
    dispatch(
      getAllGenerateReport(
        state.business_data && state.business_data.value,
        state.user_data && state.user_data.value,
        value && value.payType,
        fromDateFormat && fromDateFormat,
        toDateFormat && toDateFormat
      )
    );
    setData(true);
  };

  return (
    <>
      <div>
        <Paper
          style={{
            height: "800px",
            color: "red",
            marginBottom: "20px",
            backgroundColor: "#F9F6EE",
          }}
        >
          {/* <Form> */}
          <Card className={classes.card}>
            <section className={classes.firstSection}>
              <div>
                <Typography>Business list</Typography>
                <div>
                  <SelectBox
                    isMulti={false}
                    isSearchable={true}
                    placeholder="Select a Business"
                    name="name"
                    value={state.buisness_data || ""}
                    onChange={handleBusinessChange}
                    options={business}
                  />
                </div>
              </div>
              <div style={{ margin: "35px 0" }}>
                <Typography>Customer list</Typography>
                <SelectBox
                  isMulti={false}
                  isSearchable={true}
                  placeholder="Select a Customer"
                  name="email"
                  value={state.user_data || ""}
                  onChange={handleUserChange}
                  options={customer}
                />
              </div>
              <div>
                <Typography>Payment Type</Typography>
                <MuiSelect
                  className={classes.textField}
                  variant="outlined"
                  labelId=""
                  name="payType"
                  value={value.payType}
                  onChange={handleChange}
                >
                  {paytype.map((item) => (
                    <MenuItem value={item.id}>
                      {item.name == "CB" ? "Paid" : "Received"}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </div>
            </section>

            <section className={classes.secondSection}>
              <div>
                <Typography>From Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/M/d"
                    label="from date"
                    name="customertype"
                    value={fromDate ? moment(fromDate) : null}
                    onChange={(value) => {
                      setFromDate(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ margin: "35px 0" }}>
                <Typography>To Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy/M/d"
                    label="to date"
                    name="mobile"
                    value={toDate ? moment(toDate) : null}
                    onChange={(value) => {
                      setToDate(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div>
                <div>
                  <Button
                    htmlType="submit"
                    variant="contained"
                    onClick={handleClick}
                    disableElevation
                    className={classes.submitButton}
                    type="submit"
                  >
                    Filter
                  </Button>
                </div>
                <div className={classes.displayTable}>
                  {/* {AllReportList && AllReportList ? (<UseTable data={AllReportList} />) : ''} */}
                  <UseTable data={AllReportList} />
                </div>
              </div>
            </section>
          </Card>
          {/* </Form> */}
          {showPageNtn ? <TblPagination /> : ""}
        </Paper>
      </div>
    </>
  );
}

export default ReportsOne;
