const initialState = {
  PayInList: "",
  PayOutList: "",
}

const PaymentManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PAYIN_LIST_SUCCESS":
      return { ...state, PayInList: action.PayInList }
    case "GET_PAYIN_LIST_FAILL":
    // return {...state, error: action.error}
    case "GET_PAYOUT_LIST_SUCCESS":
      return { ...state, PayOutList: action.PayOutList }
    case "GET_PAYOUT_LIST_FAILL":
    // return {...state, error: action.error}
    default:
      return state;
  }
};

export default PaymentManagementReducer;