import { instance } from "../EcapsApi";

// GET PAY IN LIST

export const getPayInListSuccess = (data) => {
    return {
        type: 'GET_PAYIN_LIST_SUCCESS',
        PayInList: data,
    }
}
export const getPayInListError = (error) => {
    return {
        type: 'GET_PAYIN_LIST_FAIL',
        error: error,
    }
}

export const getPayInList = () => {
    return (dispatch) => {
        instance
            .get(`su/store_pay_in/`)
            .then((response) => {
                dispatch(getPayInListSuccess(
                    response.data.transactions
                )
                );
            })
            .catch((error) => {
                dispatch(getPayInListError(error))
            })
    }
}
//................................................................

// GET PAY OUT LIST

export const getPayOutListSuccess = (data) => {
    return {
        type: 'GET_PAYOUT_LIST_SUCCESS',
        PayOutList: data,
    }
}
export const getPayOutListError = (error) => {
    return {
        type: 'GET_PAYOUT_LIST_FAIL',
        error: error,
    }
}

export const getPayOutList = () => {
    return (dispatch) => {
        instance
            .get(`su/store_pay_out/`)
            .then((response) => {
                dispatch(getPayOutListSuccess(
                    response.data.transactions
                )
                );
            })
            .catch((error) => {
                dispatch(getPayOutListError(error))
            })
    }
}
//................................................................

