const initialState = {
    error: "",
    loading: false,
    postFAQcategory: "",
    FAQcategoryList: "",
    postFAQ: "",
    success: "",
    faqList: "",
    selectedCategory:""
};


const deleteFaqCategory = (state, action) => {
    const newList = state.FAQcategoryList && state.FAQcategoryList.filter(item => item.faq_category_id !== action.id);
    return { ...state, FAQcategoryList: newList, success: action.status.message }
}

const deleteFaq = (state, action) => {
    const newList = state.faqList && state.faqList.filter(item => item.faq_id !== action.id);
    return { ...state, faqList: newList, success: action.status.message }
}

const FAQReducer = (state = initialState, action) => {
    switch (action.type) {
        case "POST_FAQ_CATEGORY_SUCCESS":
            return { ...state, postFAQcategory: action.payload, success: action.payload.data.message }
        case "POST_FAQ_CATEGORY_ERROR":
            return { ...state, error: action.error }
        case "GET_FAQ_CATEGORY_SUCCESS":
            return { ...state, FAQcategoryList: action.FAQcategory }
        case "GET_FAQ_CATEGORY_ERROR":
            return { ...state, error: action.error, }
        case "GET_QA_SUCCESS":
            return { ...state, faqList: action.faqList }
        case "GET_QA_ERROR":
            return { ...state, error: action.error }
        case "DELETE_FAQ_CATEGORY_SUCCESS":
            return deleteFaqCategory(state, action)
        case "DELETE_FAQ_CATEGORY_ERROR":
            return { ...state, error: action.status.message }
        case "POST_FAQ_SUCCESS":
            return { ...state, postFAQ: action.payload, success: action.payload.data.message }
        case "POST_FAQ_error":
            return { ...state, error: action.error }
        case "DELETE_FAQ_SUCCESS":
            return deleteFaq(state, action)
        case "DELETE_FAQ_ERROR":
            return { ...state, error: action.status.message }
        case "UPDATE_FAQ_SUCCESS":
            return { ...state, postFAQ: action.payload, success: action.payload.data.message }
        case "UPDATE_FAQ_ERROR":
            return { ...state, error: action.error }
        case "UPDATE_FAQ_CATEGORY_SUCCESS":
        // return {...state, postFAQ: action.payload}
        case "UPDATE_FAQ_CATEGORY_ERROR":
        // return {...state, error: action.error}
        case "SELECTED_CATEGORY":
            return {...state , selectedCategory: action.payload}
        case 'RESET_DATA':
            return { ...state, success: '', error: ''}
        default:
            return state;
    }
}

export default FAQReducer;