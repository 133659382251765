import Axios from "axios";

//..............................................................
export const http = Axios.create({
  baseURL: process.env.REACT_APP_ECAPS_API,
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept":"application/json"
  }
});
http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["Authorization"] = accessToken;
 return config;
});


http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.log(" http error", error);
    // sessionlogout()
    return Promise.reject(error);
  }
);

//....................................................
export const instance = Axios.create({
  baseURL: process.env.REACT_APP_ECAPS_API,
  headers: { Accept: "application/json" },
});

instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["Authorization"] = accessToken;
 return config;
});

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.log("instance error", error);
    // sessionlogout()
    return Promise.reject(error);
  }
);
//..............................................................
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_ECAPS_API,
  headers: { Accept: "application/json" },
});
axios.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem("accessToken");
  config.headers["authorization"] = accessToken;
  return config;
});


axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    console.log("instance error");
    sessionlogout()
    return Promise.reject(error);
  }
);

export const sessionlogout = () => {
  const clearToken = localStorage.clear();
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
}