import React, { useEffect } from "react";
import { Card, Descriptions, Avatar } from 'antd';
import { useParams } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { IconButton } from "@material-ui/core";
import { UserOutlined } from '@ant-design/icons';
import "./StoreDetails.css";

const StoreDetails = () => {
    const { Meta } = Card;
    const { store_id } = useParams();
    console.log("store_id", store_id);
    
    useEffect(() => {

    }, [])

    const backClick = () => {
        window.history.back();
    }

    return (
        <MDBContainer fluid>
            <Card extra={<IconButton>
                <ArrowLeftOutlined onClick={backClick} />
            </IconButton>}
                title="Store Details" style={{ marginLeft: '50px' }}>
                <div className="flexbox-container" className="site-card-border-less-wrapper">
                    <MDBRow>
                        <MDBCol md="4">
                            {<Avatar size={64} icon={<UserOutlined />} />}
                            <Meta style={{ marginTop: '10px' }}
                                title="Card title"
                                description="Lorem Ipsum is simply dummy
                                 text of the printing and typesetting industry. Lorem Ipsu
                                 m has been the industry's standard dummy text ever since the
                                 1500s, when an unknown printer took a galley of type and scramb
                                 led it to make a type specimen book.
                                 PageMaker including versions of Lorem Ipsum.."
                            />
                        </MDBCol>
                        <MDBCol md="8">
                            <Descriptions title="Store Info">
                                <Descriptions.Item label="Product">Cloud Database</Descriptions.Item>
                                <Descriptions.Item label="Billing">Prepaid</Descriptions.Item>
                                <Descriptions.Item label="time">18:00:00</Descriptions.Item>
                                <Descriptions.Item label="Amount">$80.00</Descriptions.Item>
                                <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
                                <Descriptions.Item label="Official">$60.00</Descriptions.Item>
                            </Descriptions>

                            <Descriptions title="Store Info">
                                <Descriptions.Item label="Product">Cloud Database</Descriptions.Item>
                                <Descriptions.Item label="Billing">Prepaid</Descriptions.Item>
                                <Descriptions.Item label="time">18:00:00</Descriptions.Item>
                                <Descriptions.Item label="Amount">$80.00</Descriptions.Item>
                                <Descriptions.Item label="Discount">$20.00</Descriptions.Item>
                                <Descriptions.Item label="Official">$60.00</Descriptions.Item>
                            </Descriptions>
                        </MDBCol>
                    </MDBRow>
                </div>
            </Card>
        </MDBContainer>
    )
}

export default StoreDetails

