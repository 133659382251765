import { instance } from "../EcapsApi";

//..............................................................................
// IMPORT DATA IN DISTRIBUTER PAGE

export const postDistributeDataSuccess = (formData) => {
    console.log("coming here");
    return {
        type: "DISTRIBUTE_DATA_SUCCESS",
        formData: formData
    };
};

export const postDistributeDataFail = (error) => {
  return {
    type: "DISTRIBUTE_DATA_FAIL",
    error: error,
  };
};

export const postDistributeData = (formData) => {
    return (dispatch) => {
        instance
            .post('su/distributors_excel_upload', formData)
            .then((response) => {
                dispatch(
                    postDistributeDataSuccess(
                        response.data
                    )
                );
            })
            .catch((error) => {
                dispatch(postDistributeDataFail(error));
            });
    };
};

//..............................................................................
// IMPORT DATA IN RETAILER PAGE

export const postRetailerDataSuccess = (formData) => {
    return {
        type: "RETAIL_DATA_SUCCESS",
        formData: formData
    };
};

export const postRetailerDataFail = (error) => {
    return {
        type: "RETAIL_DATA_SUCCESS",
        error: error
    };
};

export const postRetailerData = (formData) => {
    return (dispatch) => {
        instance
            .post('su/retailers_excel_upload', formData)
            .then((response) => {
                dispatch(
                    postRetailerDataSuccess(
                        response.data
                    )
                );
            })
            .catch((error) => {
                dispatch(postDistributeDataFail(error));
            });
    };
};
