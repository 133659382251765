import { instance } from "../EcapsApi";

// GET BUSINESS LIST

export const getBusinessListSuccess = (data) => {
    return {
        type: 'GET_BUSINESS_LIST_SUCCESS',
        businessList: data,
    }
}
export const getBusinessListError = (error) => {
    return {
        type: 'GET_BUSINESS_LIST_FAIL',
        error: error,
    }
}

export const getBusinessList = (searchText) => {
    searchText = searchText != undefined ? searchText : ""
    console.log("searchText",searchText)
    return (dispatch) => {
        instance
            .get(`su/stores/${searchText}`)
            .then((response) => {
                dispatch(getBusinessListSuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getBusinessListError(error))
            })
    }
}
//................................................................
// Below code - APPROVE KYC

export const getKYCListSuccess = (data) => {
    return {
        type: 'GET_KYC_LIST_SUCCESS',
        kycList: data,
    }
}
export const getKYCListError = (error) => {
    return {
        type: 'GET_KYC_LIST_FAIL',
        error: error,
    }
}

export const getKYCList = () => {
    return (dispatch) => {
        instance
            .get(`su/kyc_list/`)
            .then((response) => {
                dispatch(getKYCListSuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getKYCListError(error))
            })
    }
}
//...................................................................
//KYC status approval

export const approveKycStatusSuccess = (payload,data) => {
    return {
        type: 'APPROVE_KYC_SUCCESS',
        payload: payload,
        data: data
    }
}
export const approveKycStatusError = (error) => {
    return {
        type: 'APPROVE_KYC_ERROR',
        error: error,
    }
}
export const approveKycStatus = (data) => {
    return (dispatch) => {
        instance
            .post("su/kyc_verification/", data)
            .then((response) => {
                dispatch(approveKycStatusSuccess({
                    payload: response,
                    data: data
                })
                );
            })
            .catch((error) => {
                dispatch(approveKycStatusError(error))
            })
    }
}
//...................................................................
//GET KYC DETAILS
export const getKycDetailsSuccess = (data) => {
    return {
        type: 'KYC_DETAILS_SUCCESS',
        kycDetails: data,
    }
}
export const getKycDetailsError = (error) => {
    return {
        type: 'KYC_DETAILS_ERROR',
        error: error,
    }
}
export const getKycDetails = (store_id) => {
    return (dispatch) => {
        instance
            .get(`su/kyc-by-store/${store_id}`)
            .then((response) => {
                dispatch(getKycDetailsSuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getKycDetailsError(error))
            })
    }
}
//................................................................
// Below code - MANAGE QR

export const getManageQRListSuccess = (data) => {
    return {
        type: 'GET_MANAGEQR_LIST_SUCCESS',
        ManageQRList: data,
    }
}
export const getManageQRListError = (error) => {
    return {
        type: 'GET_MANAGEQR_LIST_FAIL',
        error: error,
    }
}

export const getManageQRList = () => {
    return (dispatch) => {
        instance
            .get(`su/store_qr_codes/`)
            .then((response) => {
                dispatch(getManageQRListSuccess(
                    response.data.data
                )
                );
            })
            .catch((error) => {
                dispatch(getManageQRListError(error))
            })
    }
}

//.......................................................................
// Change the status of users

export const postQRCodeStatusSuccess = (data) => {
    return {
        type: 'POST_QRCODE_STATUS_SUCCESS',
        status: data,
    }
}
export const postQRCodeStatusError = (error) => {
    return {
        type: 'POST_QRCODE_STATUS_FAIL',
        error: error,
    }
}

export const QRCodeStatus = (data) => {
    return (dispatch) => {
        instance
            .post("su/store_qr_code_status/", data)
            .then((response) => {
                dispatch(postQRCodeStatusSuccess(
                    response.data
                )
                );
            })
            .catch((error) => {
                dispatch(postQRCodeStatusError(error))
            })
    }
}