import React, {  useEffect } from "react";
import "../../../Css/InputStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessUsersList } from "../../../Redux/Actions/UserManagement"
import { Container } from "react-bootstrap";
import { Space, Input, Table, Card } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
// import { signOut } from "../../../Redux/Actions/SigninActions";

const BusinessUser = () => {
  const dispatch = useDispatch();
  const { businessUsers } = useSelector(state => state.businessUser);
  let history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === ' undefined') {
      history.push("/auth/signin");
    }
    dispatch(getBusinessUsersList());
  },[])

  const onSearch = (e) => {
    let searchText = isNaN(e.target.value) === true ? `?name=${e.target.value}` : `?phone=${e.target.value}`;
    dispatch(getBusinessUsersList(searchText))
  }

  const items = [];
  businessUsers && businessUsers.map((item, key) => {
    key++;
    return items.push({
      key: item.user_id,
      sl_no: key,
      user_id: item.user_id,
      name: item.name,
      mobile: item.mobile,
      email: item.email,
      createdAt: item.createdAt,
      total_stores: item.total_stores,
    });
  })

  const columns = [
    {
      title: '#',
      dataIndex: 'sl_no',
      key: 'sl_no',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
    },
    {
      title: 'Business',
      dataIndex: 'total_stores',
      key: 'business',
      align: 'center',
    },
    {
      title: "View",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Link className="stores_link" to={{ pathname: "/admin/userinfo/" + record.user_id, state: { name: record.name, createdAt: record.createdAt, mobile: record.mobile, email: record.email, total_stores: record.total_stores } }}><EyeOutlined /></Link>
        </Space>
      ),
    },
  ];

  return (
    <div className="main-content">
      <Container fluid>
        <Card title="User Management /Business Users List" style={{ marginLeft: '50px' }} 
        extra={
          <div className="search_input">
            <Input className="search_input" placeholder="Search" onChange={onSearch} />
          </div> }>
          <div className="business_table">
            <Table columns={columns} dataSource={items}  />
          </div>
        </Card>
      </Container>
    </div >
  );
};

export default BusinessUser;
