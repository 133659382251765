import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import SelectBox from 'react-select';
import { Redirect } from 'react-router';
import "./Faq.css"; 
import { getFAQCategory } from "../../Redux/Actions/FAQ";

const DrawerContent = (props) => {
    const [state, setState] = useState({});
    const [err, setErrors] = useState({});
    const { FAQcategoryList } = useSelector(state => state.FAQ);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFAQCategory())
    }, [])

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken || accessToken === "" || accessToken === undefined) {
        return <Redirect to="/auth/signin"/>;
    }

    const category = [];
    FAQcategoryList && FAQcategoryList.map(item => {
        return category.push(
            { value: item.faq_category_id, label: item.type }
        );
    })

    const handleCategoryChange = (value) => {
        setState({ ...state, faq_category_data: value, faq_category_id: value.value }); 
    };

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const formValidation = () => {
        let fields = state;
        let errors = {};
        let formIsValid = true;
        if (!fields["faq_category_id"]) {
            formIsValid = false;
            errors["faq_category_id"] = "Category cannot be empty";
          }
        if (!fields["question"]) {
            formIsValid = false;
            errors["question"] = "Question cannot be empty";
        }
        if (!fields["answer"]) {
            formIsValid = false;
            errors["answer"] = "Answer cannot be empty";
        }
        setErrors({ errors });
        return formIsValid;
    }
    const handleSubmit = () => {
        if (formValidation()) {
            let newData = state;
            delete newData["faq_category_data"];
            setState({});
            props.onFormSubmit(newData);
        }
    }
    const { TextArea } = Input;

    return (
        <Form name="basic"
            labelCol={{
                span: 7,

            }}
            wrapperCol={{
                span: 10,
                offset: 1
            }}
            onFinish={handleSubmit}>
            <Form.Item label="Category" wrapperCol={{ span: 10 }}>
                <SelectBox
                    className="category_select"
                    isMulti={false}
                    isSearchable={true}
                    value={state.faq_category_data || ''}
                    onChange={handleCategoryChange}
                    options={category}
                />
                <div className="errorMsg" >{err && err.errors && err.errors.faq_category_id}</div>
            </Form.Item>
            <Form.Item label="Question">
                <TextArea name="question" rows={4} wrapperCol={{ span: 7 }} onChange={handleChange} value={state.question} />
                <div className="errorMsg">{err && err.errors && err.errors.question}</div>
            </Form.Item>
            <Form.Item label="Answer">
                <TextArea name="answer" rows={4} wrapperCol={{ span: 7 }} onChange={handleChange} value={state.answer} />
                <div className="errorMsg">{err && err.errors && err.errors.answer}</div>
            </Form.Item>
            <Form.Item wrapperCol={{ offset:12, span: 1 }}>
                <Button type="primary" className="addFaq_btn" htmlType="submit">
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
}
export default DrawerContent;
