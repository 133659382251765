const initialState = {
    businessList: "",
    kycList: "",
    status: "",
    success: "",
    error: "",
    kycDetails: "",
    ManageQRList: "",
};

const approveKyc = (state, action) => {
    state.kycDetails.kyc_status = action.payload.data.kyc_status;
    return { ...state,  success: action.payload.payload.data.message}
}

const BusinessManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BUSINESS_LIST_SUCCESS":
            return { ...state, businessList: action.businessList.list }
        case "GET_BUSINESS_LIST_FAIL":
            return { ...state, error: action.error }
        case 'RESET_DATA':
            return { ...state, status: '', message: '', success:'' }
        case "GET_KYC_LIST_SUCCESS":
            return { ...state, kycList: action.kycList.list }
        case "APPROVE_KYC_SUCCESS":
            return approveKyc(state, action)
        case "APPROVE_KYC_ERROR":
            return { ...state, error: action.error }
        case "KYC_DETAILS_SUCCESS":
            return { ...state, kycDetails: action.kycDetails }
        case "KYC_DETAILS_ERROR":
            return { ...state, kycDetails: action.error }
        case "GET_MANAGEQR_LIST_SUCCESS":
            return { ...state, ManageQRList: action.ManageQRList.list }
        case "POST_QRCODE_STATUS_SUCCESS":
            return { ...state, success: action.status.message,  }
        case "POST_QRCODE_STATUS_FAIL":
            return { ...state}
        default:
            return state;
    }
};

export default BusinessManagementReducer;