import Dashboard from "./Containers/Main/Dashboard";
import ReportsOne from './Containers/Reporting/ReportsOne'
import ApproveKYC from "./Containers/BusinessManagement/ApproveKYC";
import KycDetails from "./Containers/BusinessManagement/KycDetails";
import ManageQR from "./Containers/BusinessManagement/ManageQR";
import PayIn from "./Containers/PaymentManagement/PayIn";
import Payout from "./Containers/PaymentManagement/Payout";
import Signin from "./Containers/Authentication/Signin/Signin";
import Forgot from "./Containers/Authentication/Signin/Forgot";
import Reset from "./Containers/Authentication/Signin/Reset";
import SignOut from "./Containers/Authentication/SignOut";
import BusinessUser from "./Containers/UserManagement/BusinessUser/BusinessUser";
import Userinfo from "./Containers/UserManagement/BusinessUser/Userinfo";
import BusinessList from "./Containers/BusinessManagement/BusinessList";
import StoreDetails from "./Containers/BusinessManagement/StoreDetails";
import Customer from "./Containers/UserManagement/Customer/Customer";
import Customerinfo from "./Containers/UserManagement/Customer/Customerinfo";
import FAQContent from "./Containers/FAQ/FAQContent.js";
import AddCategory from "./Containers/FAQ/AddCategory.js";
import ImportRetailers from "./Containers/Ecaps/Retailers";
import Retailers from "./Containers/Ecaps/Retailers";
import Distributers from "./Containers/Ecaps/Distributers";


var routes = [
  {
    collapse: true,
    path: "/main",
    name: "Main",
    state: "openComponents",
    views: [
      {
        path: '/dashboard',
        layout: "/admin",
        name: 'Dashboard',
        mini: "D",
        component: Dashboard,
      }
    ]
  },
  {
    collapse: true,
    path: "/customer",
    name: "User Management",
    state: "openComponents",
    views: [
      {
        path: "/businessuser",
        layout: "/admin",
        name: "BusinessUser",
        mini: "B",
        component: BusinessUser
      },
      {
        path: "/customer",
        layout: "/admin",
        name: "Customer",
        mini: "C",
        component: Customer
      }
    ]
  },
  {
    collapse: true,
    path: "/customer",
    name: "Ecaps",
    state: "openComponents",
    views: [
      {
        path: "/distributers",
        layout: "/admin",
        name: "Distributers",
        mini: "C",
        component: Distributers
      },
      {
        path: "/retailers",
        layout: "/admin",
        name: "Retailers",
        mini: "B",
        component: Retailers
      }
     
    ]
  },
  {
    collapse: true,
    path: "/manageQR",
    name: "Business Management",
    state: "openComponents",
    views: [
      {
        path: "/businesslist",
        layout: "/admin",
        name: "Business List",
        mini: "BL",
        component: BusinessList
      },
      // {
      //   path: "/approvekyc",
      //   layout: "/admin",
      //   name: "Approve KYC",
      //   mini: "AK",
      //   component: ApproveKYC
      // },
      // {
      //   path: "/manageQR",
      //   layout: "/admin",
      //   name: "Manage QR",
      //   mini: "mqr",
      //   component: ManageQR
      // }
    ]
  },
  {
    collapse: true,
    path: "/payins",
    name: "Payment Management",
    state: "openComponents",
    views: [
      {
        path: "/payins",
        layout: "/admin",
        name: "Pay Ins",
        mini: "PI",
        component: PayIn
      },
      {
        path: "/payouts",
        layout: "/admin",
        name: "Pay Outs",
        mini: "PO",
        component: Payout
      },
    ]
  },
  {
    collapse: true,
    path: "/reporting",
    name: "Reporting",
    state: "reporting",
    views: [
      {
        path: "/generatereports",
        layout: "/admin",
        name: "Generate Reports",
        mini: "GenerateReports",
        component: ReportsOne
      }
    ]
  },
  {
    collapse: true,
    path: "/faq",
    name: "FAQ",
    state: "faq",
    views: [
      {
        path: "/faqcategory",
        layout: "/admin",
        name: "Category",
        mini: "AC",
        component: AddCategory
      },
      {
        path: "/faq",
        layout: "/admin",
        name: "List",
        mini: "SUBFAQ",
        component: FAQContent
      }
    ]
  },
  {
    collapse: true,
    path: "/profile",
    name: "Profile",
    state: "profile",
    views: [
      // {
      //   path: "/viewprofile",
      //   layout: "/admin",
      //   name: "View Profile",
      //   mini: "VP",
      //   component: ViewProfile
      // },
      // {
      //   path: "/settings",
      //   layout: "/admin",
      //   name: "Settings",
      //   mini: "S",
      //   component: Settings
      // },
      {
        path: "/signout",
        layout: "/auth",
        name: "SignOut",
        mini: "SO",
        component: SignOut
      }
    ]
  },
  {
    collapse: false,
    path: "/signin",
    layout: "/auth",
    name: "SignIn",
    state: "signin",
    show: false,
    component: Signin
  },
  {
    collapse: false,
    path: "/signout",
    layout: "/auth",
    name: "SignOut",
    state: "signout",
    show: false,
    component: SignOut
  },
  {
    collapse: false,
    path: "/forgot",
    layout: "/auth",
    name: "Forgot",
    state: "forgot",
    show: false,
    component: Forgot
  },
  {
    collapse: false,
    path: "/reset_password/:token",
    layout: "/auth",
    name: "Reset",
    state: "reset",
    show: false,
    component: Reset
  },
  {
    collapse: false,
    path: "/userinfo/:business_user_id",
    layout: "/admin",
    name: "UserInfo",
    state: "userinfo",
    show: false,
    component: Userinfo
  },
  {
    collapse: false,
    path: "/storedetails/:store_id",
    layout: "/admin",
    name: "StoreDetails ",
    state: "storedetails ",
    show: false,
    component: StoreDetails
  },
  {
    collapse: false,
    path: "/kycDetails/",
    layout: "/admin",
    name: "KycDetails",
    state: "kycdetails",
    show: false,
    component: KycDetails
  },
  {
    collapse: false,
    path: "/customerinfo/:customer_user_id",
    layout: "/admin",
    name: "CustomerInfo",
    state: "customerinfo",
    show: false,
    component: Customerinfo
  }
];
export default routes;
