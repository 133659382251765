import React, { useState, useEffect } from "react";
import { Paper, Card, Typography, Grid, Box } from "@material-ui/core";
import SharedClasses from "../../SharedFeatures/SharedClasses";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import ImportModal from "../../common/ImportModal";
import { postRetailerData } from "../../Redux/Actions/EcapsManagement";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinearProgress from "@mui/material/LinearProgress";
import PropTypes from 'prop-types';

const Retailers = () => {
  const classes = SharedClasses();
  // const [age, setAge] = React.useState("");
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState(true);
  const [linProgress, setLinProgress] = useState(false);
  const { success } = useSelector((state) => state.Distribute);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (success) {
      // message.success(success);
      dispatch({ type: "RESET_DATA" });
    }
  }, [success]);

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const onBtnClick = () => {
    setOpen(!open);
  };

  const dispatch = useDispatch();

  const readExcel = (e) => {
    const data = e.target.files[0];

    if (
      data.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFileType(false);
    } else {
      setFileType(true);
      const formData = new FormData();
      formData.append("excel", data, data.name);
      setLinProgress(true);
      setTimeout(() => {
        dispatch(postRetailerData(formData));
        message.success(`${success}File has been uploaded sucessfully`);

        setLinProgress(false);
      }, 7000);

      setOpen(!open);
    }
  };
  return (
    <>
      <Paper className={classes.commonPaper}>
        <Typography variant="h4" className={classes.typographyHead}>
          Import Retailers
        </Typography>
        <Card className={classes.distributerCard}>
          <Grid className={classes.items} container spacing={3}>
            <Grid item xs={3}>
              <Typography className={classes.typographyContent} variant="h5">
                Select Excel File
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid>
              <Button
                onClick={onBtnClick}
                className={classes.uploadbutton}
                variant="contained"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload File
              </Button>
            </Grid>
          </Grid>
        </Card>
       
         {linProgress ? (
          <LinearProgressWithLabel
          value={progress}
            style={{
              width: "1000px",
              height: "10px",
              position: "absolute",
              top: "300px",
              left: "360px",
            }}
          />
        ) : (
          " "
        )}
        {open ? (
          <ImportModal
            open={open}
            onClose={onBtnClick}
            // title={CUST_MODAL_HEADER}
            readExcel={readExcel}
            fileType={fileType}
            // message={message}
            // requestId={requestId}
          />
        ) : null}
      </Paper>
    </>
  );
};

export default Retailers;
