import React, { useEffect, useState } from "react";
import "./Faq.css";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Form,
  Space,
  Table,
  Button,
  Card,
  Row,
  Col,
  Input,
  Typography,
  Popconfirm,
  message,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";
import {
  postFAQCategory,
  getFAQCategory,
  deleteCategory,
  updateFaqCategory,
} from "../../Redux/Actions/FAQ";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AddCategory = () => {
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({ category_name: "" });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const { FAQcategoryList, postFAQcategory, success, error } = useSelector(
    (state) => state.FAQ
  );

  const isEditing = (record) => record.key === editingKey;

  const deleteCancel = (e) => {
    message.error("Cancelled");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        const faqData = {
          faq_category_id: item.id,
          category_name: row.name,
          status: "true",
        };
        dispatch(updateFaqCategory(faqData));
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {}
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === " undefined") {
      history.push("/auth/signin");
    }
    dispatch(getFAQCategory());
  }, [postFAQcategory]);

  useEffect(() => {
    const originData = [];
    FAQcategoryList &&
      FAQcategoryList.map((item, key) => {
        key++;
        originData.push({
          sl_no: key,
          key: key,
          id: item.faq_category_id,
          name: item.type,
        });
      });
    setData(originData);
  }, [FAQcategoryList]);

  let history = useHistory();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken === null || accessToken === " undefined") {
      history.push("/auth/signin");
    }
  }, []);

  useEffect(() => {
    if (success) {
      message.success(success);
      dispatch({ type: "RESET_DATA" });
    } else if (error) {
      dispatch({ type: "RESET_DATA" });
      message.error(error);
    }
  }, [success, error]);

  const onConfirm = (id) => {
    let newData;
    newData = { faq_category_id: id };
    dispatch(deleteCategory(newData));
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "sl_no",
      width: "10%",
    },
    {
      title: "Category",
      dataIndex: "name",
      width: "25%",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: "20%",
      align: "left",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <button
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
                color: "blue",
              }}
            >
              Save
            </button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <button>Cancel</button>
            </Popconfirm>
          </span>
        ) : (
          <Button>
            <ModeEditTwoToneIcon
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            ></ModeEditTwoToneIcon>
          </Button>
        );
      },
    },
    {
      title: "Delete",
      key: "id",
      width: "20%",
      align: "left",
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this Category ?"
            onConfirm={() => onConfirm(record.id)}
            onCancel={deleteCancel}
            okText="Yes"
            cancelText="No"
          >
            <Button>
              <DeleteRoundedIcon></DeleteRoundedIcon>
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const formValidation = () => {
    let entities = state;
    const newErrorsState = { ...errors };
    if (!entities["category_name"]) {
      newErrorsState.category_name = "Category cannot be empty";
      setErrors(newErrorsState);
      return false;
    } else {
      var myName = entities.category_name;
      var res = myName.match(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/);
      if (res === null) {
        newErrorsState.category_name = "Please enter only alphabets";
        setErrors(newErrorsState);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (formValidation()) {
      setErrors({});
      dispatch(postFAQCategory(state));
      setState({});
    }
  };

  return (
    <Container fluid>
      <Card title="FAQ Category" style={{ marginLeft: "50px" }}>
        <Form name="basic" onFinish={handleSubmit}>
          <Row gutter={10}>
            <Col className="gutter-row">
              <Form.Item className="category_label" label="Add Category">
                <div className="inputStyle">
                  <Input
                    name="category_name"
                    placeholder="Enter Category Name"
                    type="text"
                    onChange={handleChange}
                    value={state.category_name}
                  />
                  <div className="errorMsg">{errors.category_name}</div>
                </div>
              </Form.Item>
            </Col>
            <Col className="gutter-row">
              <Form.Item wrapperCol={{ offset: 8, span: 10 }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            dataSource={data}
            columns={mergedColumns}
            style={{ marginLeft: "20px" }}
          />
        </Form>
      </Card>
    </Container>
  );
};

export default AddCategory;
