const initialState = {
    error: "",
    loading: false,
    sucess:" ",
    AllReportList: []
};

const GenerateAllReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_All_GENERATEREPORT_SUCCESS":
            console.log("reducer action", action);
            return { ...state, AllReportList: action.AllReportListing}
        case "GET_All_GENERATEREPORT_ERROR":
            return { ...state, error: action.error, }
        default:
            return state;
    }
}

export default GenerateAllReportReducer;