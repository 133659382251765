import React from "react";
import { message } from "antd";
import SigninContent from "./SigninContent";
import { Container } from "react-bootstrap";
import "./Signin.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../Redux/Actions/SigninActions";
import { useEffect } from "react";

const Signin = () => {
  
  let history = useHistory();
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.login);
  useEffect(() => {
    if (error === undefined){
      message.error("Incorrect credentials, Try again");
      dispatch({ type: 'RESET_DATA' })
    }
  },[error])
  
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken && accessToken !== undefined) {
    history.push("/admin/dashboard");
  }

  const signin = (username, password) => {
    const loginData = {
      email: username,
      password: password,
    };
    if (loginData.email && loginData.password) {
      dispatch(login(loginData));
      if (accessToken && accessToken !== undefined) {
        history.push("/admin/dashboard");
      }
    }
  };

  return (
    <>
    {/* {error ? (<Alert type="error" message={error} banner closable />) : (null)} */}
    <div className="main">
      <Container fluid>
        <div>
          <div className="sign">Sign-In</div>
          <SigninContent signin={signin} />
        </div>
      </Container>
    </div>
    </>
  );
};

export default Signin;
