import { instance, axios, http} from "../EcapsApi";

//..............................................................................
// LOGIN 

export const loginSuccess = (access, status, message) => {
    return {
        type: "LOGIN_SUCCESS",
        accessToken: access,
        status: status,
        message: message,
    };
};

export const loginFail = (error) => {
    return {
        type: "LOGIN_FAIL",
        error: error.status,
        message: error.message
    };
};

export const login = (loginData) => {
    return (dispatch) => {
        instance
            .post("su/login", loginData)
            .then((response) => {
                if (response && response.data && response.data.status === 'success') {
                    localStorage.setItem("message", response.data.message);
                    localStorage.setItem("accessToken", response.data.token);
                    localStorage.setItem("status", response.data.status);
                    dispatch(
                        loginSuccess({
                            accessToken: response.data.token,
                            status: response.data.status,
                            message: response.data.message
                        })

                    );
                } else {
                    dispatch(loginFail(response.data));
                }
                return response;
            })
            .catch((error) => {
                dispatch(loginFail(error));
            });
    };
};

//...............................................................................

export const postRequestEmailSuccess = (data) => {
    return {
        type: 'POST_REQUEST_EMAIL_SUCCESS',
        emailRequest: data,
    }
}

export const postRequestEmailError = (error) => {
    return {
        type: 'POST_REQUEST_EMAIL_ERROR',
        error: error.message,
    }
}

export const postRequestEmail = (state) => {
    return (dispatch) => {
        axios
            .post("su/forgot_password/", state)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(postRequestEmailSuccess(
                        response.data
                    )
                    );
                } else {
                    dispatch(postRequestEmailError())
                }
            })
            .catch((error) => {
                dispatch(postRequestEmailError(error))
            })
    }
}

//...............................................................................

// REST PASSWORD   

export const postpasswordResetSuccess = (data) => {
    return {
        type: 'POST_PASSWORD_REST_SUCCESS',
        passwordReset: data,
    }
}

export const postpasswordResetError = (error) => {
    return {
        type: 'POST_PASSWORD_REST_ERROR',
        error: error,
    }
}

export const postpasswordReset = (token, state) => {
    return (dispatch) => {
        instance
            .post(`su/reset_password/${token}`, state)
            .then((response) => {
                dispatch(postpasswordResetSuccess(
                    response.data
                )
                );
            })
            .catch((error) => {
                dispatch(postpasswordResetError(error))
            })
    }
}

//...............................................................................

// SIGN OUT

export const signOutSuccess = (data) => {
    return {
        type: "SIGNOUT_SUCCESS",
        status: data,
    };
};

export const signOutFail = (error) => {
    return {
        type: "SIGNOUT_FAIL",
        error: error,
    };
};

export const signOut = () => {
    return (dispatch) => {
        instance
            .post("su/logout/")
            .then((response) => {
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("party");
                dispatch(signOutSuccess(response.data.status));
            })
            .catch((error) => {
                dispatch(signOutFail(error.response));
            });
    };
};