import React, { useEffect, useState } from "react";
import "./Faq.css";
import { useDispatch, useSelector } from "react-redux";
import DrawerContent from "./DrawerContent";
import { Form, Table, Button, Card, Popconfirm, message, Space, Drawer, Col, Row, InputNumber, Input, Typography } from 'antd';
import { postFAQ, getFAQCategory, getQA, deleteQA, updateFAQ } from "../../Redux/Actions/FAQ";
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Select } from 'antd';
import { useHistory } from "react-router-dom";
const { TextArea } = Input;

const EditableCell = ({
    editing,
    dataIndex,
    title,
    question,
    answer,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <TextArea rows={4} />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please input your ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const FAQContent = () => {
    const dispatch = useDispatch();
    const [showDrawer, setShowDrawer] = useState(false);
    const [drawerType, setDrawerType] = useState("");
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const { FAQcategoryList, faqList, success, error, selectedCategory } = useSelector(state => state.FAQ);

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken === null || accessToken === ' undefined') {
          history.push("/auth/signin");
        }
      }, [])

    useEffect(() => {
        dispatch(getFAQCategory())
        if (success) {
            message.success(success);
            dispatch({ type: 'RESET_DATA' })
            dispatch(getQA(selectedCategory));
        } else if (error) {
            message.success(error);
            dispatch({ type: 'RESET_DATA' })
        }
    }, [success, error])

    useEffect(() => {
        const originData = [];
        faqList && faqList.map((item, key) => {
            key++;
            return originData.push({
                sl_no: key,
                key: key,
                id: item.faq_id,
                faq_id: item.faq_id,
                question: item.question,
                answer: item.answer
            });
        })
        setData(originData);
    }, [faqList])

    const isEditing = (record) => record.key === editingKey;

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                const faqData = { faq_id: item.faq_id, question: row.question, answer: row.answer }
                dispatch(updateFAQ(faqData))
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
        }
    };

    let history = useHistory();

    useEffect(() => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken === null || accessToken === ' undefined') {
            history.push("/auth/signin");
        }
    }, [])

    const category = [];
    FAQcategoryList && FAQcategoryList.map(item => {
        return category.push(
            { value: item.faq_category_id, label: item.type }
        );
    })

    const handleCategoryChange = (value) => {
        dispatch({ type: 'SELECTED_CATEGORY', payload: value })
        dispatch(getQA(value));
    };

    const onClose = () => {
        setShowDrawer(false);
    };

    const onAdd = () => {
        setShowDrawer(true);
        setDrawerType("add");
    };


    const edit = (record) => {
        form.setFieldsValue({
            question: '',
            answer: '',
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const deleteCancel = (e) => {
        message.error('Cancelled');
    }

    const onConfirm = (id) => {
        let newData;
        newData = { 'faq_id': id }
        dispatch(deleteQA(newData));
    };

    const onFormSubmit = (newData) => {
        onClose();
        dispatch(postFAQ(newData));
    }

    const columns = [
        {
            title: "#",
            dataIndex: "sl_no",
            align: "center"
        },
        {
            title: 'Question',
            dataIndex: 'question',
            editable: true,
            width: '35%',
            align: "justify"
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            editable: true,
            width: '35%',
            align: "justify"
        },
        {
            title: 'Edit',
            dataIndex: 'operation',
            align: "left",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            href="javascript:;"
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                                color: 'blue',
                            }}
                        >
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <ModeEditTwoToneIcon className="editButton" disabled={editingKey !== ''} onClick={() => edit(record)}></ModeEditTwoToneIcon>
                );
            },
        },
        {
            title: "Delete",
            key: "id",
            align: "left",
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure to delete this FAQ ?"
                        onConfirm={() => onConfirm(record.id)}
                        onCancel={deleteCancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined></DeleteOutlined>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                question: col.question,
                answer: col.answer,
                editing: isEditing(record),
            }),
        };
    });
    return (
        <div style={{ margin: "65px" }}>
            <Card title="FAQ">
                <Row>
                    <Col lg={{ span: 10, offset: 2 }}>
                        <Form.Item style={{ width: '500px', marginLeft: '-110px' }}>
                            <Select
                                isMulti={false}
                                showSearch={true}
                                placeholder="Select a category"
                                value={selectedCategory && selectedCategory}
                                onChange={handleCategoryChange}
                                options={category}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 2, offset: 9 }}>
                        <Button type="primary" className="addfaq_btn" onClick={onAdd} >
                            Add FAQ
                        </Button>
                    </Col>
                </Row>
                <Form form={form} component={false}>
                    <Table components={{ body: { cell: EditableCell } }}
                        columns={mergedColumns}
                        dataSource={data}
                        style={{ marginLeft: '20px' }} />
                </Form>
            </Card>
            <Drawer
                title={"Add FAQ"}
                placement="right"
                width={750}
                closable={true}
                onClose={onClose}
                visible={showDrawer}
                key="drawer"
            >
                <DrawerContent drawerType={drawerType} type="Faq" onFormSubmit={onFormSubmit} />
            </Drawer>
        </div>
    );
};
export default FAQContent;
