import { border, color,background } from "@material-ui/system";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  distributerCard: {
    height: "250px",
    width: "1200px",
    position: "absolute",
    top: "100px",
    left: "250px",
    border: "5px solid #013b8e",
    borderRadius:"10px"
  },
  newButton: {
    position: 'absolute !important',
    fontFamily: 'Poppins !important',
    fontWeight: '600 !important',
    right: '25px !important',
    top: '45px',
    height: '40px !important',
    background: '#5A5DF6 !important',
    // padding: '15px 0 !important',
    fontSize: '15px',
    color: '#FFFFFF !important',
    width: '150px',
    boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px !important',
    '&:hover': {
      background: "#7f81e6",
      border: '1px solid #7f81e6',
    },
  },

  commonPaper: {
    height: "800px",
    width: "1400px",
  },
  items: {
    position:"absolute",
    top:"70px",
    left:"50px"
  },
  typographyHead:{
      position:"absolute",
      top:"40px",
      left:"250px"
  },
  typographyContent:{
    position:"absolute",
    top:"40px",
    left:"120px"

  },
  uploadbutton:{
      position:"absolute",
      top:"40px",
      right:"360px",
      fontWeight:"5rem",
  }
}));

export default useStyles;
